import React, { useEffect, useRef, useState } from 'react'
import Button from '../../Buttons/Button';

const EditNameRecordingModel = ({setaudioname,audioname,closeModalViewEditNameRecording}) => {
    const [ name,setname]=useState('')
    const [errorMessage,seterrorMessage]=useState('')
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
        setname(audioname)
      }, [audioname]);
    const handlesave = ()=>{
      if(name !=='')
        {
          setaudioname(name)
          closeModalViewEditNameRecording()
          setname('')
         
        }
        else{
          seterrorMessage('Please provide the name of the recording')
        }
     
    }
    const handlecancel = ()=>{
      setname(audioname)
      closeModalViewEditNameRecording()
    }
    return (
      <div>
        <div className="mx-2">
          <div className="my-4">Edit Recording <small className="red-star">*</small></div> 
          <div className="input-container-adduser">
            <input   autoComplete="off" type="text" className="border-0 border-bottom border-black w-100" value={name}  onChange={(e)=>{seterrorMessage(''); setname(e.target.value)}}  ref={inputRef}/>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </div>
          <div className="d-flex justify-content-between my-5">
            <div
              onClick={handlecancel}
              className="cancel-btn p-2 "
            >
               <Button value="Cancel"/>
            </div>
            <div className="submit-btn p-2 px-3" onClick={handlesave}> <Button value="Save"/></div>
          </div>
        </div>
      </div>
    );
  };


export default EditNameRecordingModel
