import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import vizzhylogo from "../../../Assets/Header/vizzhy_logo.svg";
import Loginfood from "../../../Assets/Homepage/Loginfood.svg";
import { sendPasswordResetEmail } from "../../../Service/ApiServices";
import "../../../Style/UserModel.scss";
import Button from "../../../Utils/Buttons/Button";
import { useAuth } from "../../../Service/Context/AuthProvider";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailDomainError, setEmailDomainError] = useState(false);
  const [error, seterror] = useState("");
  const [isLoading, setIsLoading] = useState(false);
const {logoutremove}= useAuth()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setEmailError(false);
    setEmailDomainError(false);

    if (!emailRegex.test(email)) {
      setEmailError(true);
      return;
    }

    if (!email.endsWith("@vizzhy.com")) {
      setEmailDomainError(true);
      return;
    }

    setIsLoading(true);

    const resultAction = await dispatch(sendPasswordResetEmail(email));

    setIsLoading(false);

    if (sendPasswordResetEmail.fulfilled.match(resultAction)) {
      seterror("");
      logoutremove()
      navigate("/checkyouremail");
    } else {
      seterror(resultAction.payload);
      console.error(
        "Password reset email failed:",
        resultAction.payload || resultAction.error
      );
    }
  };

  return (
    <div className="forgotpassword-page">
      <div className="login-main row w-100">
        <div className="col-sm-7 forget-pass">
          <img
            src={vizzhylogo}
            alt="vizzhyLogo"
            className="vizzhy-logo m-3 ms-4"
          />
          <img className="forgotpassword-img ms-5" src={Loginfood} alt="img" />
        </div>
        <div className="col-sm-5 d-flex justify-content-center align-items-center">
          <div className="w-100">
            <form className="forgotpassword-form">
              <p className="fs-3 mb-1 fw-bold">Forgot Password ?</p>
              <div className="mt-4">
                <div
                  className={`mb-4 login-icon ${emailError ? "has-error" : ""}`}
                >
                  <label className="fs-6">Email</label>
                  <div className="login-icon mt-2">
                    <input
                      autoComplete="off"
                      type="email"
                      className="form-control form-control-lg input-field rounded-3 "
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        seterror("");
                      }}
                    />
                  </div>
                  {emailError && (
                    <div className="error-message text-danger">
                      Email is required
                    </div>
                  )}
                  {emailDomainError && (
                    <p className="error-message text-danger">
                      Only vizzhy email addresses are accepted
                    </p>
                  )}
                </div>
                {error && (
                  <div className="error-message text-danger">{error}</div>
                )}
                <div
                  className={`${
                    email === "" ? "reset-before-button" : "reset-button"
                  } button px-2 py-2 cursor-pointer`}
                  onClick={handleResetPassword}
                >
                  <Button
                    value={
                      isLoading ? (
                        <span className="loader"></span>
                      ) : (
                        "Reset Password"
                      )
                    }
                    disabled={isLoading}
                  />
                </div>
                <div className="fs-6 d-flex justify-content-center mt-3">
                  <Link to="/login" className="text-dark Forgotlink">
                    <span>Back to Login</span>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
