// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch } from "react-redux";
// import { unwrapResult } from "@reduxjs/toolkit";
// import {
//   fetchUserNotification,
//   updateUserNotification,
// } from "../../Service/ApiServices";
// import { useAuth } from "../../Service/Context/AuthProvider";
// import "../../Style/UserModel.scss";

// const NotificationPopup = () => {
//   const [isNotificationOpen, setIsNotificationOpen] = useState(false);
//   const { userData } = useAuth();
//   const notificationRef = useRef(null);
//   const dispatch = useDispatch();
//   const [datanotifi, setDatanotifi] = useState([]);

//   useEffect(()=>{
//     const fetchNotifications = async () => {
//       if (userData) {
//         try {
//           const resultAction = await dispatch(
//             fetchUserNotification({
//               token: userData.token,
//             })
//           );
  
//           const data = unwrapResult(resultAction);
//           setDatanotifi(data);
//         } catch (error) {
//           console.error("Error fetching notifications:", error);
//         }
//       }
//     };
//     fetchNotifications();
//   },[!isNotificationOpen])

//   const handleNotificationClick = () => {
//     setIsNotificationOpen(!isNotificationOpen);
//     // if (!isNotificationOpen) {
//     //   fetchNotifications();
//     // }
//   };

//   const handleClickOutside = (event) => {
//     if (
//       notificationRef.current &&
//       !notificationRef.current.contains(event.target)
//     ) {
//       setIsNotificationOpen(false);
//     }
//   };

//   const handleMarkAsRead = async (notfy_id) => {
//     try {
//       await dispatch(
//         updateUserNotification({
//           notfy_id,

//           token: userData.token,
//         })
//       );

//       const updatedNotifications = datanotifi.map((notification) =>
//         notification.notfy_id === notfy_id
//           ? { ...notification, is_read: 1 }
//           : notification
//       );
//       setDatanotifi(updatedNotifications);
//     } catch (error) {
//       console.error("Error marking notification as read:", error);
//     }
//   };
//   const hasUnreadNotifications = datanotifi.some(notification => notification.is_read === 0);

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const formatTime = (datetimeString) => {
//     const date = new Date(datetimeString);
//     return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
//   };

//   const formatRelativeDate = (datetimeString) => {
//     const date = new Date(datetimeString);
//     const today = new Date();

//     if (
//       date.getDate() === today.getDate() &&
//       date.getMonth() === today.getMonth() &&
//       date.getFullYear() === today.getFullYear()
//     ) {
//       return "Today";
//     } else {
//       const diffTime = Math.abs(today - date);
//       const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//       return `${diffDays} days ago`;
//     }
//   };

//   return (
//     <div>
//       <div className="container-fluid display-relative-custom">
//         <span
//           className="material-symbols-outlined header-backcolor"
//           role="button"
//           aria-expanded={isNotificationOpen ? "true" : "false"}
//           onClick={handleNotificationClick}
//         >
//           notifications
//         </span>
//         {hasUnreadNotifications && <div className="red-dot-notification"></div>}
//       </div>

//       {isNotificationOpen && (
//         <div>
//           <div className="background-dim"></div>
//           <div className="notification-popup" ref={notificationRef}>
//             <div className="card card-body">
//               <div className="notification-header">
//                 <h4>Notification</h4>
//               </div>
//               {datanotifi && datanotifi.length > 0 ? (
//                 <div>
//                   {datanotifi.map((notification, index) => (
//                     <React.Fragment key={notification.notfy_id}>
//                       {index === 0 ||
//                       formatRelativeDate(notification.created_datetime) !==
//                         formatRelativeDate(
//                           datanotifi[index - 1].created_datetime
//                         ) ? (
//                         <div className="notification-date">
//                           {formatRelativeDate(notification.created_datetime)}
//                         </div>
//                       ) : null}
//                       <div
//                         className={`notification-item ${
//                           notification.is_read === 0 ? "fw-bolder text-dark" : ""
//                         }`}
//                         key={notification.notfy_id}
//                       >
//                         <div className="row align-items-center w-100 border-bottom py-2">
//                           <div className="col-md-9">
//                             <div
//                               className="mb-0"
//                               onClick={() => {
//                                 if (notification.is_read === 0) {
//                                   handleMarkAsRead(notification.notfy_id);
//                                 }
//                               }}
//                             >
//                               {notification.message}
//                             </div>
//                           </div>
//                           <div className="col text-end">
//                             <span className="">
//                               {formatTime(notification.created_datetime)}
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </React.Fragment>
//                   ))}
//                 </div>
//               ) : (
//                 <p>No notifications</p>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default NotificationPopup;
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  fetchUserNotification,
  updateUserNotification,
} from "../../Service/ApiServices";
import { useAuth } from "../../Service/Context/AuthProvider";
import "../../Style/UserModel.scss";
import { useNavigate } from "react-router-dom";

const NotificationPopup = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { userData } = useAuth();
  const notificationRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [datanotifi, setDatanotifi] = useState([]);

  const fetchNotifications = async () => {
    if (userData) {
      try {
        const resultAction = await dispatch(
          fetchUserNotification({
            token: userData.token,navigate
          })
        );

        const data = unwrapResult(resultAction);
        setDatanotifi(data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (isNotificationOpen) {
      fetchNotifications();
    }
  }, [isNotificationOpen]);

  const handleNotificationClick = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setIsNotificationOpen(false);
    }
  };

  const handleMarkAsRead = async (notfy_id) => {
    try {
      await dispatch(
        updateUserNotification({
          notfy_id,
          token: userData.token,navigate
        })
      );

      const updatedNotifications = datanotifi.map((notification) =>
        notification.notfy_id === notfy_id
          ? { ...notification, is_read: 1 }
          : notification
      );
      setDatanotifi(updatedNotifications);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const hasUnreadNotifications = datanotifi.some(
    (notification) => notification.is_read === 0
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatTime = (datetimeString) => {
    const date = new Date(datetimeString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatRelativeDate = (datetimeString) => {
    const date = new Date(datetimeString);
    const today = new Date();

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return "Today";
    } else {
      const diffTime = Math.abs(today - date);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return `${diffDays} days ago`;
    }
  };

  return (
    <div>
      <div className="container-fluid display-relative-custom">
        <span
          className="material-symbols-outlined header-backcolor"
          role="button"
          aria-expanded={isNotificationOpen ? "true" : "false"}
          onClick={handleNotificationClick}
        >
          notifications
        </span>
        {hasUnreadNotifications && <div className="red-dot-notification"></div>}
      </div>

      {isNotificationOpen && (
        <div>
          <div className="background-dim"></div>
          <div className="notification-popup" ref={notificationRef}>
            <div className="card card-body">
              <div className="notification-header">
                <h4>Notification</h4>
              </div>
              {datanotifi && datanotifi.length > 0 ? (
                <div>
                  {datanotifi.map((notification, index) => (
                    <React.Fragment key={notification.notfy_id}>
                      {index === 0 ||
                      formatRelativeDate(notification.created_datetime) !==
                        formatRelativeDate(
                          datanotifi[index - 1].created_datetime
                        ) ? (
                        <div className="notification-date">
                          {formatRelativeDate(notification.created_datetime)}
                        </div>
                      ) : null}
                      <div
                        className={`notification-item ${
                          notification.is_read === 0 ? "fw-bolder text-dark" : ""
                        }`}
                        key={notification.notfy_id}
                      >
                        <div className="row align-items-center w-100 border-bottom py-2">
                          <div className="col-md-9">
                            <div
                              className="mb-0"
                              onClick={() => {
                                if (notification.is_read === 0) {
                                  handleMarkAsRead(notification.notfy_id);
                                }
                              }}
                            >
                              {notification.message}
                            </div>
                          </div>
                          <div className="col text-end">
                            <span className="">
                              {formatTime(notification.created_datetime)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              ) : (
                <p>No notifications</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;
