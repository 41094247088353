import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Style/Table.scss";
import Button from "../Buttons/Button";
import AdminReassignModel from "../Model/AdminModel/AdminReassignModel";
import Model from "../Model/Model";

const AdminViewTables = ({
  data,
  researcherName,
  qaName,
  itemsPerPage = 10,
  visible,
  card,
  qaId,
  researcherid,
  role,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [reassignModel, setReassignModel] = useState(false);
  const [isReassign, setIsReassign] = useState(false);
  const [selectedRfnIds, setSelectedRfnIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setCheckboxes(data?.map((item) => ({ ...item, checked: false })));
  }, [data]);

  useEffect(() => {
    setIsReassign(checkboxes?.some((checkbox) => checkbox.checked));
  }, [checkboxes]);

  useEffect(() => {
    const currentFilteredPageData = getCurrentPageData();
    setSelectAll(
      currentFilteredPageData?.every((checkbox) => checkbox.checked)
    );
  }, [currentPage, checkboxes, searchTerm, filterStatus]);

  const openModalReassignModel = () => {
    const selectedIds = checkboxes
      ?.filter((checkbox) => checkbox.checked)
      ?.map((checkbox) => checkbox.rfn_id);
    setSelectedRfnIds(selectedIds);
    setReassignModel(true);
  };

  const closeModalModalReassignModel = () => {
    setReassignModel(false);
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData?.slice(startIndex, endIndex);
  };

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes?.map((checkbox) =>
      checkbox.rfn_id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
  };

  const handleSelectAll = () => {
    const currentPageData = getCurrentPageData();
    const isAllSelected = currentPageData?.every(
      (checkbox) => checkbox.checked
    );
    const updatedCheckboxes = checkboxes?.map((checkbox) =>
      currentPageData.includes(checkbox)
        ? { ...checkbox, checked: !isAllSelected }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    const halfWay = Math.ceil(maxPageNumbersToShow / 2);
    let startPage, endPage;

    const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

    if (totalPages <= maxPageNumbersToShow) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= halfWay) {
      startPage = 1;
      endPage = maxPageNumbersToShow;
    } else if (currentPage + halfWay - 1 >= totalPages) {
      startPage = totalPages - maxPageNumbersToShow + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - halfWay + 1;
      endPage = currentPage + halfWay - 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (startPage > 1) {
      pageNumbers.unshift("...");
      pageNumbers.unshift(1);
    }

    if (endPage < totalPages) {
      pageNumbers.push("...");
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
  };

  const filteredData = checkboxes?.filter((checkbox) => {
    const matchesSearch = checkbox.food_name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter =
      filterStatus === "" || checkbox.status === filterStatus;
    return matchesSearch && matchesFilter;
  });

  const totalItems = filteredData?.length;
  const startIdx = (currentPage - 1) * itemsPerPage + 1;
  const endIdx = Math.min(currentPage * itemsPerPage, totalItems);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = renderPageNumbers();

  const hasSelectableRows = filteredData?.some(
    (checkbox) =>
      checkbox.status !== "Ready To Review" && checkbox.status !== "Approved"
  );

  const handlecanclefunction = () => {
    navigate("/vfs/admin-dashboard", { state: { card } });
  };
  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);
  return (
    <div className="research-reassign w-100 row mt-5">
      <div className="research-reassign-main">
        <div className="filters-wrapper ">
          <div className="custom-select-wrapper d-flex justify-content-between gap-2">
            <div className="d-flex">
              {visible && (
                <div>
                  <h4 className="fw-bold">
                    {researcherName
                      ? researcherName?.charAt(0)?.toUpperCase() +
                      researcherName?.slice(1)
                      : ""}
                  </h4>
                  <span>Researcher</span>
                </div>
              )}
              <div className="ms-5">
                <h4 className="fw-bold">
                  {qaName
                    ? qaName?.charAt(0)?.toUpperCase() + qaName?.slice(1)
                    : ""}
                </h4>
                {visible && <span>QA</span>}
              </div>
            </div>
            <div className="d-flex justify-content-end flex-filter ">
              <div className="datatable-display-filter me-3">
                <select
                  id="statusFilter"
                  className="form-control custom-select filter"
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="To Do">To Do</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Ready To Review">Ready To Review</option>
                  <option value="Sent To Rework">Sent To Rework</option>
                  <option value="Approved">Approved</option>
                  <option value="Reworked">Reworked</option>
                </select>
              </div>
              <div className="DataTableresearch">
                <div>
                  <span className="material-symbols-outlined DataTableresearch-search search-icon">
                    search
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className=" searchinput"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="datatable-display mt-3 table-container">
          {totalItems > 0 ? (
            <table>
              <thead>
                <tr className="text-nowrap">
                  <th>
                    {hasSelectableRows && (
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="input-checked"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                        <div className="ms-2"> Select All</div>
                      </div>
                    )}
                  </th>

                  <th>Recipes</th>
                  <th>Allocated Date</th>
                  <th>Days Taken</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {getCurrentPageData()?.map((checkbox) => (
                  <tr key={checkbox.rfn_id}>
                    <td>
                      <span className="ms-4">
                        {" "}
                        {checkbox.status !== "Ready To Review" &&
                          checkbox.status !== "Approved" && (
                            <input
                              className="input-checked"
                              type="checkbox"
                              checked={checkbox.checked}
                              onChange={() =>
                                handleCheckboxChange(checkbox.rfn_id)
                              }
                            />
                          )}
                      </span>
                    </td>
                    <td className="fw-bold">{checkbox.food_name}</td>
                    <td>{checkbox.task_date} </td>
                    <td>{checkbox.days_taken < 0 ? '-' : `${checkbox.days_taken} Days`}</td>
                    <td className="status">
                      <div
                        className={`px-2 py-1 roundpill-status text-nowrap text-center rounded-pill  status-${checkbox.status.replace(
                          / /g,
                          "-"
                        )}`}
                      >
                        {checkbox.status}
                       
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center">No data available</div>
          )}
        </div>

        {totalItems > 0 && (
          <div className="d-flex w-100 justify-content-between mt-3 custom-select-wrapper-1">
            <div className="tablemetrixdata">
              Showing data {startIdx} to {endIdx} of {totalItems}
            </div>
            <div className="pagination">
              <button
                onClick={() =>
                  setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)
                }
                disabled={currentPage === 1}
              >
                <span class="material-symbols-outlined">chevron_left</span>
              </button>
              {pageNumbers?.map((number, index) => (
                <button
                  key={index}
                  onClick={() => {
                    if (number !== "...") {
                      goToPage(number);
                    }
                  }}
                  className={currentPage === number ? "active" : ""}
                  disabled={number === "..."}
                >
                  {number}
                </button>
              ))}
              <button
                onClick={() =>
                  setCurrentPage(
                    currentPage === totalPages ? totalPages : currentPage + 1
                  )
                }
                disabled={currentPage === totalPages}
              >
                <span class="material-symbols-outlined">chevron_right</span>
              </button>
            </div>
          </div>
        )}

        <div>
          <div className="mt-5 row w-100">
            <div className="mb-3 d-flex justify-content-between align-items-center button-media link-tag">
              <div
                className="button cancel-btn px-5 p-2 cursor-pointer admin-view"
                onClick={handlecanclefunction}
              >
                <Button value="Back " />
              </div>
              {isReassign && (
                <div
                  className="button submit-btn px-5 p-2 cursor-pointer d-flex justify-content-end"
                  onClick={openModalReassignModel}
                >
                  <Button value="Reassign" />
                </div>
              )}
            </div>
          </div>

          {reassignModel && (
            <Model
              data="modal-sm"
              showModal={reassignModel}
              content={
                <AdminReassignModel
                  closeModalModalReassignModel={closeModalModalReassignModel}
                  qaName={qaName}
                  researcherName={researcherName}
                  role={role}
                  selectedRfnIds={selectedRfnIds}
                  qaId={qaId}
                  researcherid={researcherid}
                  card={card}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

AdminViewTables.propTypes = {
  data: PropTypes.array.isRequired,
  researcherName: PropTypes.string.isRequired,
  qaName: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number,
  userId: PropTypes.string.isRequired,
};

export default AdminViewTables;
