import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import vizzhylogo from "../../../Assets/Header/vizzhy_logo.svg";
import Loginfood from "../../../Assets/Homepage/Loginfood.svg";
import { VFSlogin } from "../../../Service/ApiServices";
import { useAuth } from "../../../Service/Context/AuthProvider";
import "../../../Style/UserModel.scss";
import Button from "../../../Utils/Buttons/Button";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailDomainError, setEmailDomainError] = useState(false);
  const [email, setEmail] = useState("");
  const [loginError, setLoginError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const {
    updateUseractive,
    updateUserData,
    getrememberme,
    storeEncryptedData,
    removerememberme,
  } = useAuth();
  const dispatch = useDispatch();
  const decryptData = (encryptedData) => {
    try {
      const decodedData = atob(encryptedData);
      const sanitizedData = decodedData.replace(/[^\x20-\x7E]/g, "");
      const decryptedData = JSON.parse(sanitizedData);
      return decryptedData;
    } catch (error) {
      return null;
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const data = getrememberme();
    if (data) {
      const valuedata = decryptData(data);
      if (valuedata) {
        setRememberMe(true);
      }
      const savedEmail = valuedata?.email;
      const savedPassword = valuedata?.password;
      if (savedEmail && savedPassword) {
        setEmail(savedEmail);
        setPassword(savedPassword);
      }
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      return;
    }
    const emailDomainIsValid = email.endsWith("@vizzhy.com");
    setEmailError(email === "");
    setEmailDomainError(!emailDomainIsValid);
    setPasswordError(password === "");

    if (email !== "" && emailDomainIsValid && password !== "") {
      try {
        const action = VFSlogin({ email, password, rememberMe });
        const resultAction = await dispatch(action);

        const data = resultAction.payload;
        if (data.status === "error") {
          setLoginError(data.message);
        } else {
          const userData = {
            email:email,
            userId: data.userId,
            token: data.access_token,
            role: data.role,
            loggedName: data.userName,
            assigned_qa_id: data.assigned_qa_id,
            assigned_qa_name: data.assigned_qa_name,
          };
          updateUseractive({ data: true });
          updateUserData(userData);

          if (rememberMe) {
            const rememberData = { email, password };
            storeEncryptedData(rememberData);
          } else {
            removerememberme();
          }
          setLoginError("");
          switch (data.role) {
            case "admin":
              navigate("/vfs/admin-dashboard");
              break;
            case "super_admin":
              navigate("/vfs/admin-dashboard");
              break;
            case "qa":
              navigate("/vfs/qa-dashboard");
              break;
            case "researcher":
              navigate("/vfs/researcher-dashboard");
              break;
            default:
              setLoginError("Unknown user role");
          }
        }
      } catch (error) {
        console.error("Error occurred during login:", error);
        setLoginError("Invalid email or password");
      }
    }
  };

  return (
    <div className="login-page">
      <div className="login-main row w-100">
        <div className="col-sm-7">
          <img
            src={vizzhylogo}
            alt="vizzhyLogo"
            className="vizzhy-logo m-3 ms-4"
          />
          <img
            className="login-img ms-5"
            src={Loginfood}
            alt="img"
            loading="lazy"
          />
        </div>

        <div className="col-sm-5 d-flex justify-content-center align-items-center">
          <div className="w-100">
            <form className="login-form">
              <p className="fs-6 mb-1">Welcome to Vizzhy Food Studio</p>
              <h2 className="fw-bolder mb-5">Login</h2>

              <div>
                <div
                  className={`mb-3 login-icon ${
                    emailError || emailDomainError ? "has-error" : ""
                  }`}
                >
                  <label className="fs-6">Email </label>
                  <div className="login-icon">
                    <input
                      autoComplete="off"
                      type="email"
                      className="form-control form-control-lg input-field mt-2"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setLoginError("");
                      }}
                    />
                  </div>
                  {emailError && (
                    <p className="error-message text-danger">
                      Email is required
                    </p>
                  )}
                  {emailDomainError && (
                    <p className="error-message text-danger">
                      Only vizzhy email addresses are accepted
                    </p>
                  )}
                </div>

                <div className={`mb-3 ${passwordError ? "has-error" : ""}`}>
                  <label className="fs-6">Password</label>
                  <div className="login-icon">
                    <input
                      autoComplete="off"
                      type={showPassword ? "text" : "password"}
                      className="form-control form-control-lg input-field mt-2"
                      id="passwordField"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setLoginError("");
                      }}
                    />
                    <span
                      className="material-symbols-outlined mail-eye-icons"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? "visibility" : "visibility_off"}
                    </span>
                  </div>
                  {passwordError && (
                    <p className="error-message text-danger">
                      Password is required
                    </p>
                  )}
                </div>

                {loginError && (
                  <p className="error-message text-danger">{loginError}</p>
                )}

                <div className="d-flex justify-content-between">
                  <div className="form-check mb-md-0 d-flex justify-content-start">
                    <input
                      className="form-check-input me-2 input-field"
                      type="checkbox"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    <span className="fs-6 remeber-me">Remember me</span>
                  </div>

                  <div className="link-container">
                    <p className="fs-6 forgotpassword-text">
                      <Link to="/forgot-password" className="link-tag">
                        Forgot Password?
                      </Link>
                    </p>
                  </div>
                </div>

                <div
                  className="button submit-btn px-2 py-2 cursor-pointer"
                  onClick={handleLogin}
                >
                  <Button value="Login" />
                </div>
                <div className="fs-6 d-flex justify-content-center mt-3">
                  <Link to="/" className="text-dark Forgotlink">
                    <span>Back to Home</span>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
