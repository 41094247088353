import React from "react";
import Loginfood from "../../../Assets/Homepage/Loginfood.svg";
import vizzhylogo from "../../../Assets/Header/vizzhy_logo.svg";
import "../../../Style/UserModel.scss";
import Button from "../../../Utils/Buttons/Button";
import { Link } from "react-router-dom";

function CheckYourEmail() {
  const openEmailLink = () => {
    const emailLink =
      "https://accounts.google.com/AccountChooser?continue=https://mail.google.com/mail/&service=mail";
    window.open(emailLink, "_blank");
  };

  return (
    <div className="login-page">
      <div className="login-main row w-100">
        <div className="col-sm-7">
          <img
            src={vizzhylogo}
            alt="vizzhyLogo"
            className="vizzhy-logo m-3 ms-4"
          />
          <img className="login-img ms-5" src={Loginfood} alt="img" />
        </div>
        <div className="col-sm-5 d-flex justify-content-center align-items-center">
          <div className="w-100">
            <form className="login-form">
              <p className="fw-bold fs-3">Check Your Email</p>
              <p className="fs-6 mb-1 text-muted ">
                <div className="checkmail">
                  We sent you an email with instructions to reset your password
                </div>
                {/* <div className="checkmail">your email address</div> */}
              </p>
              <div
                className="button submit-btn px-2 py-2 cursor-pointer mt-2"
                onClick={openEmailLink}
              >
                <Button value=" Go To Email" />
              </div>
            </form>
            <div className="fs-6 d-flex justify-content-center mt-3">
              <Link to="/login" className="text-dark Forgotlink">
                <span>Back to Login</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckYourEmail;
