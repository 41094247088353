import { createSlice } from "@reduxjs/toolkit";
import StatusCode from "../../../Utils/StatusCode";
import {
  VFSlogin,
  sendPasswordResetEmail,
  userLogout,
  resetPassword,
  fetchUserNotification,
  fetchResetDataCheck,
  updateUserNotification,
  fetchUserInfodetails,
} from "../../ApiServices";

const UserSlice = createSlice({
  name: "user",
  initialState: {
    data: [],
    status: "",
    error: null,
    successMessage: [],
    datanotifi: [],
    tokenstates:[],
    loading: false,
    updatenotifi: [],
    datauservalue:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(VFSlogin.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(VFSlogin.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.data = action.payload;
      })
      .addCase(VFSlogin.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.error.message; // Ensure error message is set
      })
      .addCase(sendPasswordResetEmail.pending, (state) => {
        state.status = StatusCode.LOADING;
        state.error = null;
      })
      .addCase(sendPasswordResetEmail.fulfilled, (state) => {
        state.status = StatusCode.IDLE;
      })
      .addCase(sendPasswordResetEmail.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.error.message; // Ensure error message is set
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.successMessage = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.error.message; // Ensure error message is set
      })
      .addCase(fetchUserNotification.pending, (state) => {
        state.status = StatusCode.LOADING;
        state.error = null;
      })
      .addCase(fetchUserNotification.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.datanotifi = action.payload;
      })
      .addCase(fetchUserNotification.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.payload; // Ensure error message is set
      })
      .addCase(updateUserNotification.pending, (state) => {
        state.status = StatusCode.LOADING;
        state.error = null;
      })
      .addCase(updateUserNotification.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.updatenotifi = action.payload;
      })
      .addCase(updateUserNotification.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.payload; // Ensure error message is set
      })
      .addCase(userLogout.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.status = StatusCode.IDLE;
      })
      .addCase(userLogout.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.payload;
      })
      
      .addCase(fetchResetDataCheck.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchResetDataCheck.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.tokenstates = action.payload;
      })
      .addCase(fetchResetDataCheck.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        
        state.error = action.error.message; // Ensure error message is set
      })
      .addCase(fetchUserInfodetails.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchUserInfodetails.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.datauservalue = action.payload;
      })
      .addCase(fetchUserInfodetails.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.error.message; // Ensure error message is set
      })

  },
});
export default UserSlice.reducer;
