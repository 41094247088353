import { createSlice } from "@reduxjs/toolkit";
import StatusCode from "../../../Utils/StatusCode";
import {
  AdminAddUser,
  AdminCountDashboard,
  fetchAdminUserDetails,
  updateUserStatus,
  fetchAdminViewList,
  fetchCountrycodesandqadetails,
  FetchEditUser,
  AdminpostEditUser,
  AdminExcelDownload,
  ReassignQA,
  AdminExcelUpload,
  TaskAllocate,
  fetchResearcherCount,
  DownloadApprovedFood,
  ViewAdminfetchFoodDetails,
  fetchUploadedIngredients,
  fetchAdminPendingFood,
} from "../../ApiServices";

const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    data: [],
    status: "",
    statusupload:'',
    statusadminuserdetais:"",
    statusadminadduser:'',
    approvedfoodstatus:'',
    taskallocatestatus:'',
    error: null,
    datavalue: [],
    useradd: [],
    countryCodes: [],
    qaList: [],
    postedituser: [],
    reassignqa: [],
    downloadtemplet: [],
    uploadtemplet: [],
    allocatetask: [],
    DownloadApprovedFood: [],
    researcherList: [],
    statusdownload: "",
    fetchdata: [],
    ApprovedFoodView:[],
    approvedview:"",
    ZipCode:[],
    zipcode:[],
    foodpending:[],
  },
  reducers: {},

  extraReducers: (builder) => {
    builder

      .addCase(AdminCountDashboard.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(AdminCountDashboard.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.data = action.payload;
      })
      .addCase(AdminCountDashboard.rejected, (state) => {
        state.status = StatusCode.ERROR;
      })

      .addCase(fetchAdminUserDetails.pending, (state) => {
        state.statusadminuserdetais = StatusCode.LOADING;
      })
      .addCase(fetchAdminUserDetails.fulfilled, (state, action) => {
        state.statusadminuserdetais = StatusCode.IDLE;
        state.fetchdata = action.payload;
      })
      .addCase(fetchAdminUserDetails.rejected, (state) => {
        state.statusadminuserdetais = StatusCode.ERROR;
      })
      .addCase(updateUserStatus.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.datavalue = action.payload;
      })
      .addCase(updateUserStatus.rejected, (state) => {
        state.status = StatusCode.ERROR;
      })
      .addCase(fetchAdminViewList.pending, (state) => {
        state.fetchAdminViewListstatus = StatusCode.LOADING;
      })
      .addCase(fetchAdminViewList.fulfilled, (state, action) => {
        state.fetchAdminViewListstatus = StatusCode.IDLE;
        state.tablevalue = action.payload;
      })
      .addCase(fetchAdminViewList.rejected, (state,action) => {
        state.fetchAdminViewListstatus = StatusCode.ERROR;
        state.tablevalue = action.payload;
      })

      .addCase(AdminAddUser.pending, (state) => {
        state.statusadminadduser = StatusCode.LOADING;
      })
      .addCase(AdminAddUser.fulfilled, (state, action) => {
        state.statusadminadduser = StatusCode.IDLE;
        state.useradd = action.payload;
      })
      .addCase(AdminAddUser.rejected, (state,action) => {
        state.statusadminadduser = StatusCode.ERROR;
        state.useradd = action.payload;
      })

      
      .addCase(fetchCountrycodesandqadetails.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchCountrycodesandqadetails.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.countryCodes = action.payload.codes;
        state.qaList = action.payload.qa;
      })
      .addCase(fetchCountrycodesandqadetails.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.payload;
      })

      .addCase(FetchEditUser.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(FetchEditUser.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.fetchedituser = action.payload;
      })
      .addCase(FetchEditUser.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.payload;
      })

      .addCase(AdminpostEditUser.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(AdminpostEditUser.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.postedituser = action.payload;
      })
      .addCase(AdminpostEditUser.rejected, (state) => {
        state.status = StatusCode.ERROR;
      })

      .addCase(ReassignQA.pending, (state) => {
        state.status = StatusCode.LOADING;
      })

      .addCase(ReassignQA.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.reassignqa = action.payload;
      })
      .addCase(ReassignQA.rejected, (state) => {
        state.status = StatusCode.ERROR;
      })
      .addCase(AdminExcelDownload.pending, (state) => {
        state.statusdownload = StatusCode.LOADING;
      })
      .addCase(AdminExcelDownload.fulfilled, (state, action) => {
        state.statusdownload = StatusCode.IDLE;
        state.downloadtemplet = action.payload;
      })
      .addCase(AdminExcelDownload.rejected, (state) => {
        state.statusdownload = StatusCode.ERROR;
      })

      .addCase(AdminExcelUpload.pending, (state) => {
        state.statusupload = StatusCode.LOADING;
      })
      .addCase(AdminExcelUpload.fulfilled, (state, action) => {
        state.statusupload = StatusCode.IDLE;
        state.uploadtemplet = action.payload;
      })
      .addCase(AdminExcelUpload.rejected, (state) => {
        state.statusupload = StatusCode.ERROR;
      })
      .addCase(fetchResearcherCount.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchResearcherCount.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.researcherList = action.payload;
      })
      .addCase(fetchResearcherCount.rejected, (state) => {
        state.status = StatusCode.ERROR;
      })

      .addCase(TaskAllocate.pending, (state) => {
        state.taskallocatestatus = StatusCode.LOADING;
      })
      .addCase(TaskAllocate.fulfilled, (state, action) => {
        state.taskallocatestatus = StatusCode.IDLE;
        state.allocatetask = action.payload;
      })
      .addCase(TaskAllocate.rejected, (state) => {
        state.taskallocatestatus = StatusCode.ERROR;
      })


      .addCase(DownloadApprovedFood.pending, (state) => {
        state.approvedfoodstatus = StatusCode.LOADING;
      })
      .addCase(DownloadApprovedFood.fulfilled, (state, action) => {
        state.approvedfoodstatus = StatusCode.IDLE;
        state.DownloadApprovedFood = action.payload;
      })
      .addCase(DownloadApprovedFood.rejected, (state) => {
        state.approvedfoodstatus = StatusCode.ERROR;
      })

      .addCase(ViewAdminfetchFoodDetails.pending, (state) => {
        state.approvedview = StatusCode.LOADING;
      })
      .addCase(ViewAdminfetchFoodDetails.fulfilled, (state, action) => {
        state.approvedview = StatusCode.IDLE;
        state.ApprovedFoodView = action.payload;
      })
      .addCase(ViewAdminfetchFoodDetails.rejected, (state) => {
        state.approvedview = StatusCode.ERROR;
      })

      .addCase(fetchUploadedIngredients.pending, (state) => {
        state.zipcode = StatusCode.LOADING;
      })
      .addCase(fetchUploadedIngredients.fulfilled, (state, action) => {
        state.zipcode = StatusCode.IDLE;
        state.ZipCode = action.payload;
      })
      .addCase(fetchUploadedIngredients.rejected, (state) => {
        state.zipcode = StatusCode.ERROR;
      })

      .addCase(fetchAdminPendingFood.pending, (state) => {
        state.foodpending = StatusCode.LOADING;
      })
      .addCase(fetchAdminPendingFood.fulfilled, (state, action) => {
        state.foodpending = StatusCode.IDLE;
        state.foodpending = action.payload;
      })
      .addCase(fetchAdminPendingFood.rejected, (state) => {
        state.foodpending = StatusCode.ERROR;
      });
  },
});

export default AdminSlice.reducer;