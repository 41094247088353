import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import vizzhylogo from "../../../Assets/Header/vizzhy_logo.svg";
import Loginfood from "../../../Assets/Homepage/Loginfood.svg";
import linkexpired from "../../../Assets/Homepage/Timemanagement.svg";
import { fetchResetDataCheck, resetPassword } from "../../../Service/ApiServices"; // Adjust the path as necessary
import "../../../Style/UserModel.scss";
import { unwrapResult } from "@reduxjs/toolkit";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loadingexpire, setLoadingExpire] = useState(false); // Assuming this state is managed elsewhere
const [timesetout,settimesetout] =useState()
  const { valuedata } = useParams();

  useEffect(()=>{
    const handleFetchReset = async () => {
      try {
       
        const resultAction =  await dispatch(fetchResetDataCheck(valuedata));
      const data = unwrapResult(resultAction);
        settimesetout(data.expiresIn)
       
        
      } catch (error) {
        console.error('Failed to fetch reset data:', error);
        
        setLoadingExpire(true)
      }
    };
    handleFetchReset()
  },[valuedata])
  useEffect(()=>{
    if (timesetout) {
      setTimeout(() => {
        setLoadingExpire(true)
      }, timesetout * 60000);
    }
  },[timesetout])
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { successMessage = '', error } = useSelector((state) => state.user || {});

  const handleResetPassword = async (e) => {
    e.preventDefault();

    const isPasswordValid = validatePassword();
    const isConfirmPasswordValid = validateConfirmPassword();

    if (!isPasswordValid || !isConfirmPasswordValid) {
      return;
    }

    try {
      const resultAction = await dispatch(resetPassword({ valuedata, newPassword: password, confirmPassword }));
      const result = unwrapResult(resultAction);
      if(result.message === 'Password reset successful')
        {
          navigate("/reset-password-success");
        }
    } catch (error) {
      toast.error(
        error.message || "An error occurred while resetting the password"
      );
    }
  };

  const validatePassword = () => {
    const isValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$])[A-Za-z\d!@#$]{8,}$/.test(
        password
      );
    setPasswordError(
      isValid
        ? ""
        : "Password must contain at least 8 characters, including uppercase, lowercase, digit, and special character."
    );
    return isValid;
  };

  const validateConfirmPassword = () => {
    const isValid = password === confirmPassword;
    setConfirmPasswordError(isValid ? "" : "Passwords do not match.");
    return isValid;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  if (loadingexpire) {
    return (
      <div className="expired-link d-flex justify-content-center align-items-center">
        <div>
          <div className="text-center">
            <img className="w-75" src={linkexpired} alt="Link expired" />
          </div>
          <h4 className="text-center">Sorry, the link has expired.</h4>
          <div className="text-center">
            Please contact our support team at  &nbsp;
            <a href="mailto:technical.support@vizzhy.com">
                technical.support@vizzhy.com
            </a>
            <div className="mt-4"><a href="/login">Back to Login</a></div>
            
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="setnewpassword-page">
      <div className="login-main row w-100 ">
        <div className="col-sm-7">
          <img
            src={vizzhylogo}
            alt="vizzhyLogo"
            className="vizzhy-logo m-3 ms-4"
          />
          <img className="setnewpassword-img ms-5" src={Loginfood} alt="img" />
        </div>

        <div className="col-sm-5 d-flex justify-content-center align-items-center">
          <div className="w-100">
            <form
              onSubmit={handleResetPassword}
              className="setnewpassword-form"
            >
              <h2 className="fw-bolder mb-5">Set A New Password</h2>
              <div className="">
                <div
                  className={`mb-3 login-icon ${
                    passwordError ? "has-error" : ""
                  }`}
                >
                  <label className="mb-3 fs-6">Create Password</label>
                  <div className="login-icon">
                    <input
                      autoComplete="off"
                      type={showPassword ? "text" : "password"}
                      className="form-control form-control-lg input-field rounded-3"
                      placeholder=" Password"
                      value={password}
                      onChange={(e) => {
                        setPasswordError("");
                        setPassword(e.target.value);
                      }}
                    />
                    {passwordError && (
                      <div className="error-message text-danger fs-6 mb-3">
                        {passwordError}
                      </div>
                    )}
                    <span
                      className="material-symbols-outlined mail-eye-icons"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? "visibility" : "visibility_off"}
                    </span>
                  </div>

                  <div className="mt-2">
                    <p className="charector-required mb-1">
                      The Password must contain
                    </p>
                    <p className="charector-required mb-1">
                      {password.length >= 8 && (
                        <span className="text-success">&#10003;</span>
                      )}{" "}
                      Minimum 8 characters
                    </p>
                    <p className="charector-required mb-1">
                      {/(?=.*[A-Z])/.test(password) && (
                        <span className="text-success">&#10003;</span>
                      )}{" "}
                      An UPPERCASE character (ABCD)
                    </p>
                    <p className="charector-required mb-1">
                      {/(?=.*[a-z])/.test(password) && (
                        <span className="text-success">&#10003;</span>
                      )}{" "}
                      A lowercase character (abcd)
                    </p>
                    <p className="charector-required mb-1">
                      {/(?=.*\d)/.test(password) && (
                        <span className="text-success">&#10003;</span>
                      )}{" "}
                      A number (1234)
                    </p>
                    <p className="charector-required mb-1">
                      {/(?=.*[!@#$])/.test(password) && (
                        <span className="text-success">&#10003;</span>
                      )}{" "}
                      A symbol (@#$)
                    </p>
                  </div>
                </div>

                <div
                  className={`mb-3 login-icon ${
                    confirmPasswordError ? "has-error" : ""
                  }`}
                >
                  <label className="mt-4 mb-2 fs-6">Confirm Password</label>
                  <div className="login-icon">
                    <input
                      autoComplete="off"
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control form-control-lg input-field rounded-3"
                      placeholder=" Password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPasswordError("");
                        setConfirmPassword(e.target.value);
                      }}
                    />
                    <span
                      className="material-symbols-outlined mail-eye-icons"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? "visibility" : "visibility_off"}
                    </span>
                  </div>
                  {confirmPasswordError && password?.length > 0 && (
                    <div className="error-message text-danger">
                      {confirmPasswordError}
                    </div>
                  )}
                </div>
                {successMessage && (
                  <div className="success-message text-success fs-6 mb-3">
                    {successMessage}
                  </div>
                )}
                {error && (
                  <p className="error-message text-danger mb-3">{error}</p>
                )}

                <div className="d-grid gap-2 mt-4">
                  <button className="btn reset-button" type="submit">
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
