import React, { useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ViewfetchFoodDetails } from "../../../../Service/ApiServices";
import { useAuth } from "../../../../Service/Context/AuthProvider";
import Button from "../../../../Utils/Buttons/Button";
function ResReadyToView() {
  const key = useParams();
  const decryptData = (encryptedData) => {
    try {
      const base64 = encryptedData.datavalue
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      const decodedData = atob(base64);
      const sanitizedData = decodedData.replace(/[^\x20-\x7E]/g, "");
      const decryptedData = JSON.parse(sanitizedData);
      return decryptedData;
    } catch (error) {
      return null;
    }
  };
  const statekey = decryptData(key);
  const { userData } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { datavalue } = useSelector((state) => state.researcher);

  useEffect(() => {
    if (userData) {
      dispatch(
        ViewfetchFoodDetails({
          rfnid: statekey?.data?.rfn_id,
          statusvalue: "READY-TO-REVIEW",
          token: userData.token,
          navigate
        })
      );
    }
  }, [dispatch, userData]);

  return (
    <div className="RecipesView mx-4">
      <div className="RecipesView-main card w-100 p-3 my-3 ">
        <>
          <h4 className="food-details-heading fw-bolder mt-3 mb-4">
            Food Details
          </h4>
          <div className="food-details-list-1st row">
            <div className="col-md-3 ">
              <div>
                <small className="text-nowrap fw-bold">Name of the Food</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    type="text"
                    value={datavalue?.food_details?.name_of_food}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">
                  Different Spellings(Commonly Used)
                </small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.different_spellings}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Cuisine</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.cuisine}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Country</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.country}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="food-details-list-2st row mt-4">
            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">
                  Portion (Standard Values)
                </small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.portion}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">
                  Quantity (Standard Values)
                </small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.quantity}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">
                  Unit (Standard Values)
                </small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.unit}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">
                  Processed Food Level
                </small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.processed_food_level}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="food-details-list-3st row mt-4">
            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Category of Food</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.category_of_food}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Food Group</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.food_group}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Nutrient Rich</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.nutrient_rich}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">
                  Therapeutic Use of Food
                </small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.therapepeutic_use_of_food}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="food-details-list-4st row mt-4">
            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">
                  Type of Triglyceride
                </small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.food_details?.type_of_triglyceride}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <h4 className="ingredients-details-heading fw-bolder mt-5 mb-4">
            Ingredients Details
          </h4>

          <div className="ingredients-details row">
            <div className="left col-sm-6 leftscroll">
              <div className="table-container">
                <table>
                  <thead>
                    <th className="bg-white text-nowrap">
                      Name of the Ingredient
                    </th>
                    <th className="bg-white">Quantity</th>
                    <th className="bg-white">Portion</th>
                    <th className="bg-white">Amount</th>
                  </thead>
                  <tbody>
                    {datavalue?.ingredients_details?.added_ingredients?.map(
                      (ingredient) => (
                        <tr
                          key={ingredient.name_of_ingredient}
                          className="border-0"
                        >
                          <td>
                            <input
                              className="form-control"
                              value={ingredient?.name_of_ingredient}
                              type="text"
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              value={ingredient?.quantity}
                              type="text"
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              value={ingredient?.portion}
                              type="text"
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              value={ingredient?.amount}
                              type="text"
                              disabled
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="right col-sm-6 mt-3">
              <div>
                <div>
                  <small className="text-nowrap fw-bold">
                    Reference for Food Ingredients
                  </small>
                  <div className="mt-2">
                    <input
                      className="w-100 form-control"
                      type="text"
                      value={datavalue?.ingredients_details?.references}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="Glycaemic Index row mt-4">
                <div className="col-sm-4">
                  <div>
                    <small className="text-nowrap fw-bold">
                      Glycaemic Index
                    </small>
                    <div className="mt-2">
                      <input
                        className="form-control"
                        type="text"
                        value={datavalue?.ingredients_details?.gi.value}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div>
                    <small className="text-nowrap fw-bold">
                      Reference for Glycaemic Index:
                    </small>
                    <div className="mt-2">
                      <input
                        className="form-control"
                        type="text"
                        value={
                          datavalue?.ingredients_details?.gi.reference_for_gi
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h4 className="food-name-in-different-languages fw-bolder mt-5 mb-4">
            Languages
          </h4>

          <div className="food-name-in-different-languages-list-2st row">
            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Hindi</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.hindi}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Bengali</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.bengali}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Gujarati</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.gujarati}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Marathi</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.marathi}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="food-name-in-different-languages-list-3st row mt-3">
            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Tamil</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.tamil}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Telugu</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.telugu}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Kannada</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.kannada}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Malayalam</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.malayalam}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="food-name-in-different-languages-list-4st row mt-3">
            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Punjabi</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.punjabi}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Odia</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.odia}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <small className="text-nowrap fw-bold">Urdu</small>
                <div className="mt-2">
                  <input
                    className="form-control"
                    value={datavalue?.languages?.urdu}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="food-audio row mt-3">
            <div className="food-image col-sm-6">
              <h4 className="add-food-images fw-bolder mt-5 mb-4">
                Food Images
              </h4>
              <div className="image-gallery">
                <div className="row">
                  {datavalue?.upload?.image?.map((imgUrl, index) => (
                    <div key={index} className="col-sm-3 mb-3">
                      <img
                        src={imgUrl}
                        alt={`Food Image ${index + 1}`}
                        className="img-fluid"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="audio col-sm-6">
              <h4 className="add-food-images fw-bolder mt-5 mb-4">
                Recorded Food Name
              </h4>
              <div className="audio-recoding d-flex">
                {datavalue?.upload?.audio && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="audio-relative mt-3">
                      <div className="audio-absolute fw-bold text-nowrap">
                        {datavalue?.upload?.audio_file_name}
                      </div>
                      <AudioPlayer
                        src={datavalue?.upload.audio}
                        showJumpControls={false}
                        layout={"stacked-reverse"}
                        showDownloadProgress={false}
                        hasDefaultKeyBindings={false}
                        autoPlayAfterSrcChange={false}
                        showFilledVolume={false}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
      {datavalue?.comments?.length > 0 && (
        <div className="mb-3">
          <textarea disabled className="w-100" value={datavalue?.comments} />
        </div>
      )}
      <div className=" mb-3 d-flex justify-content-between align-items-center button-media link-tag">
        <Link
          to="/vfs/researcher-dashboard"
          state={{ card: 4 }}
          className="link-tag"
        >
          <div className=" button cancel-btn px-5 p-2">
            <Button value="Return to list" />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default ResReadyToView;
