import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import "../../../Style/Admin.scss";
import Button from "../../Buttons/Button";
import {
  fetchCountrycodesandqadetails,
  ReassignQA,
  fetchResearcherCount,
} from "../../../Service/ApiServices";
import { useAuth } from "../../../Service/Context/AuthProvider";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";

function AdminReassignModel({
  closeModalModalReassignModel,
  role,
  qaName,
  selectedRfnIds,
  userId,
  qaId,
  researcherid,
  card,
  researcherName,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { qaList, qaCount, researcherList, researcherCount } = useSelector(
    (state) => state.admin
  );
  const { userData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData.token) {
      if (role === "qa") {
        dispatch(fetchCountrycodesandqadetails({ token: userData.token, navigate }));
      } else if (role === "researcher") {
        dispatch(fetchResearcherCount({ token: userData.token, navigate }));
      }
    }
  }, [dispatch, userData.token, role]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedOption) {
      setError("Please select an option");
    } else {
      setError("");

      try {
        const response = await dispatch(
          ReassignQA({
            datavalue: {
              [`old_${role}_id`]: role === "qa" ? qaId : researcherid,
              [`new_${role}_id`]: selectedOption.value,
              rfn_id: selectedRfnIds,
              created_by: userData.userId,
            },
            token: userData.token,
            role: role,
            navigate
          })
        );

        if (response?.payload?.status === "success") {
          closeModalModalReassignModel();
          toast.success('Reallocated successfully!');
          navigate("/vfs/admin-dashboard", { state: { card } });

        }
        else if (response?.payload?.message) {
          toast.error(response?.payload?.message)
        }

      } catch (error) {
        console.error("Reallocation failed", error);
        setError("Reassignment failed. Please try again.");
      }
    }
  };

  const handleChange = (selectedOption) => {

    setSelectedOption(selectedOption);
    setError("");
  };

  return (
    <div className="admin-reassign-modal px-2">
      <h4 className="fw-bold">Reassign {role === 'qa' ? "QA" : "Researcher"}</h4>
      <div className="counts">
        {/* {role === "qa" && <div>Total QAs: {qaCount}</div>}
        {role === "researcher" && (
          <div>Total Researchers: {researcherCount}</div>
        )} */}
      </div>
      <div className="current-name mt-5">
        <div className="label">
          Current
          {/* {role ? role.charAt(0).toUpperCase() + role.slice(1) : ""} */}
        </div>
        <div className="input-wrapper">
          <input
            autoComplete="off"
            className="form-control"
            type="text"
            value={role === 'qa' ? qaName : researcherName}
            aria-label="readonly input example"
            readOnly
          />
        </div>
      </div>

      <div className="reassign-name">
        <div className="label-wrapper">
          <div className="label">
            {/* Reassign {role ? role.charAt(0).toUpperCase() + role.slice(1) : ""} */}
            Assigned to
          </div>
          <div>
            <small className="required-star">*</small>
          </div>
        </div>

        <div className="select-wrapper">
          <ReactSelect
            name="qa"
            value={selectedOption}
            onChange={handleChange}
            options={
              role === "qa"
                ? qaList?.filter((QA) => QA.user_id !== qaId).map((QA) => ({
                  value: QA.user_id,
                  label: `${QA.user_fullname} (Allocated researcher): ${QA.count}`,
                }))
                : role === "researcher"
                  ? researcherList
                    ?.filter((researcher) => researcher.user_id !== researcherid)
                    .map((researcher) => ({
                      value: researcher.user_id,
                      label: `${researcher.user_fullname} (Allocated Task): ${researcher.taskCount}`,
                    }))
                  : []
            }
            classNamePrefix="form-select"
            placeholder="Search"
          />
          {error && <label className="error-message">{error}</label>}
        </div>
      </div>

      <div className="buttons mt-5 mb-4">
        <div
          className="button cancel-btn"
          onClick={closeModalModalReassignModel}
        >
          <Button value="Cancel" />
        </div>
        <div className="button submit-btn" onClick={handleSubmit}>
          <Button value="Submit" />
        </div>
      </div>
    </div>
  );
}

AdminReassignModel.propTypes = {
  closeModalModalReassignModel: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  qaName: PropTypes.string.isRequired,
  selectedRfnIds: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  qaId: PropTypes.string.isRequired,
  researcherid: PropTypes.string.isRequired,
};

export default AdminReassignModel;
