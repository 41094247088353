export const cuisines = [
  { value: "american", label: "American" },
  { value: "arab", label: "Arab" },
  { value: "asian", label: "Asian" },
  { value: "chinese", label: "Chinese" },
  { value: "continental", label: "Continental" },
  { value: "indian", label: "Indian" },
  { value: "italian", label: "Italian" },
  { value: "japanese", label: "Japanese" },
  { value: "korean", label: "Korean" },
  { value: "mediterranean", label: "Mediterranean" },
  { value: "mexican", label: "Mexican" },
  { value: "thai", label: "Thai" },
  { value: "turkish", label: "Turkish" },
];

export const countryOptions = [
  { value: "China", label: "China" },
  { value: "Europe", label: "Europe" },
  { value: "India", label: "India" },
  { value: "Italy", label: "Italy" },
  { value: "Japan", label: "Japan" },
  { value: "Korea", label: "Korea" },
  { value: "Mexico", label: "Mexico" },
  { value: "Thailand", label: "Thailand" },
  { value: "Turkey", label: "Turkey" },
  { value: "UAE", label: "UAE" },
  { value: "USA", label: "USA" },
];

export const portionOptions = [
  { value: "Bottle", label: "Bottle" },
  { value: "Bowl/Large Cup", label: "Bowl/Large Cup" },
  { value: "Can", label: "Can" },
  { value: "Large Glass", label: "Large Glass" },
  { value: "Medium Cup", label: "Medium Cup" },
  { value: "Medium Glass", label: "Medium Glass" },
  { value: "Ounce", label: "Ounce" },
  { value: "Peg", label: "Peg" },
  { value: "Piece", label: "Piece" },
  { value: "Pint", label: "Pint" },
  { value: "Plate", label: "Plate" },
  { value: "Scoop", label: "Scoop" },
  { value: "Slice", label: "Slice" },
  { value: "Small Cup", label: "Small Cup" },
  { value: "Small Glass", label: "Small Glass" },

  { value: "Tablespoon", label: "Tablespoon" },
  { value: "Teacup", label: "Teacup" },
  { value: "Teaspoon", label: "Teaspoon" },
];

export const quantityOptions = [
  { value: "5", label: "5" },
  { value: "15", label: "15" },
  { value: "30", label: "30" },
  { value: "50", label: "50" },
  { value: "66", label: "66" },
  { value: "100", label: "100" },
  { value: "150", label: "150" },
  { value: "180", label: "180" },
  { value: "200", label: "200" },
  { value: "250", label: "250" },
  { value: "300", label: "300" },
  { value: "330", label: "330" },
  { value: "500", label: "500" },
];

export const unitOptions = [
  { value: "Grams- Solid or semi solid", label: "Grams- Solid or Semi Solid" },
  { value: "10Liters- liquids0", label: "Liters- Liquids" },
  { value: "ML- liquids", label: "ML- liquids" },
];

export const processedOptions = [
  { value: "Processed", label: "Processed" },
  {
    value: "Processed culinary ingredients",
    label: "Processed Culinary Ingredients",
  },
  { value: "Ultra processed", label: "Ultra Processed" },
  { value: "Unprocessed", label: "Unprocessed" },
];

export const categoryOptions = [
  { value: "Eggetarian", label: "Eggetarian" },
  { value: "Non vegetarian", label: "Non Vegetarian" },
  { value: "Pescitarian", label: "Pescitarian" },
  { value: "Vegan", label: "Vegan" },
  { value: "Vegetarian", label: "Vegetarian" },
];

export const foodGroupOptions = [
  { value: "Beverages", label: "Beverages" },
  { value: "Cereal", label: "Cereal" },
  { value: "Dessert", label: "Dessert" },
  { value: "Flesh Foods", label: "Flesh Foods" },
  { value: "Fruits", label: "Fruits" },
  { value: "Marine and Sea Foods", label: "Marine and Sea Foods" },
  { value: "Milk and milk products", label: "Milk and Milk Products" },
  { value: "Nuts", label: "Nuts" },
  {
    value: "Poultry and Poultry Products",
    label: "Poultry and Poultry Products",
  },
  { value: "Pulse", label: "Pulse" },
  { value: "Seeds", label: "Seeds" },
  { value: "Vegetables", label: "Vegetables" },
  { value: "Other", label: "Other" },
];

export const nutrientRichOptions = [
  { value: "25-OH-D3", label: "25-OH-D3" },
  { value: "3,4-Dihydroxy benzoic acid", label: "3,4-Dihydroxy Benzoic Acid" },
  { value: "3-Hydroxybenzaldehyde", label: "3-Hydroxybenzaldehyde" },
  { value: "Ajugose Oligosaccharides", label: "Ajugose Oligosaccharides" },
  { value: "Alanine", label: "Alanine" },
  { value: "Alpha - Carotene", label: "Alpha - Carotene" },
  { value: "Alpha - Linoleic", label: "Alpha - Linoleic" },
  { value: "Alpha_Tocopherols", label: "Alpha Tocopherols" },
  { value: "Alpha_Tocotrienols", label: "Alpha Tocotrienols" },
  { value: "Alpha-Tocopherol Equivalent", label: "Alpha-Tocopherol Equivalent" },
  { value: "Aluminium", label: "Aluminium" },
  { value: "Apigenin", label: "Apigenin" },
  { value: "Apigenin-6-C-glucoside", label: "Apigenin-6-C-Glucoside" },
  { value: "Arachidic", label: "Arachidic" },
  { value: "Arachidonic", label: "Arachidonic" },
  { value: "Arginine", label: "Arginine" },
  { value: "Arsenic", label: "Arsenic" },
  { value: "Ash", label: "Ash" },
  { value: "Aspartic Acid", label: "Aspartic Acid" },
  { value: "Behenic", label: "Behenic" },
  { value: "Beta - Carotene", label: "Beta - Carotene" },
  { value: "Beta_Tocopherols", label: "Beta Tocopherols" },
  { value: "Beta_Tocotrienols", label: "Beta Tocotrienols" },
  { value: "Beta-Cryptoxanthin", label: "Beta-Cryptoxanthin" },
  { value: "Biotin_B7", label: "Biotin B7" },
  { value: "Butyric", label: "Butyric" },
  { value: "Cadmium", label: "Cadmium" },
  { value: "Caffeic acid", label: "Caffeic Acid" },
  { value: "Calcium", label: "Calcium" },
  { value: "Campesterol Phytosterols", label: "Campesterol Phytosterols" },
  { value: "Capric", label: "Capric" },
  { value: "Caproic", label: "Caproic" },
  { value: "Caprylic", label: "Caprylic" },
  { value: "Carbohydrates", label: "Carbohydrates" },
  { value: "Catechin", label: "Catechin" },
  { value: "Chlorogenic acid", label: "Chlorogenic Acid" },
  { value: "Cholecalciferol (D3)", label: "Cholecalciferol (D3)" },
  { value: "Chromium", label: "Chromium" },
  { value: "Cinnamic acid", label: "Cinnamic Acid" },
  { value: "Cis-Aconitic acid", label: "Cis-Aconitic Acid" },
  { value: "Citric Acid", label: "Citric Acid" },
  { value: "Cobalt", label: "Cobalt" },
  { value: "Copper", label: "Copper" },
  { value: "Cystine", label: "Cystine" },
  { value: "Daidzein", label: "Daidzein" },
  { value: "Delta_Tocopherols", label: "Delta Tocopherols" },
  { value: "Delta_Tocotrienols", label: "Delta Tocotrienols" },
  { value: "Eicosadienoic", label: "Eicosadienoic" },
  { value: "Eicosanoic", label: "Eicosanoic" },
  { value: "Eicosenoic", label: "Eicosenoic" },
  { value: "Elaidic", label: "Elaidic" },
  { value: "Ellagic acid", label: "Ellagic Acid" },
  { value: "Energy", label: "Energy" },
  { value: "Epicatechin", label: "Epicatechin" },
  { value: "Epigallo catechin", label: "Epigallo Catechin" },
  { value: "Epigallo catechin 3-gallate", label: "Epigallo Catechin 3-Gallate" },
  { value: "Ergocalciferol(D2)", label: "Ergocalciferol (D2)" },
  { value: "Erucic", label: "Erucic" },
  { value: "Ferulic acid", label: "Ferulic Acid" },
  { value: "Fructose", label: "Fructose" },
  { value: "Fumaric Acid", label: "Fumaric Acid" },
  { value: "Gallic acid", label: "Gallic Acid" },
  { value: "Gallocatechin", label: "Gallocatechin" },
  { value: "Gallocatechin gallate", label: "Gallocatechin Gallate" },
  { value: "Gamma - Carotene", label: "Gamma - Carotene" },
  { value: "Gamma_Tocopherols", label: "Gamma Tocopherols" },
  { value: "Gamma_Tocotrienols", label: "Gamma Tocotrienols" },
  { value: "Genistein", label: "Genistein" },
  { value: "Glucose", label: "Glucose" },
  { value: "Glutamic Acid", label: "Glutamic Acid" },
  { value: "Glycine", label: "Glycine" },
  { value: "Hesperetin", label: "Hesperetin" },
  { value: "Hesperidin", label: "Hesperidin" },
  { value: "Histidine", label: "Histidine" },
  { value: "Insoluble dietary fibre", label: "Insoluble Dietary Fibre" },
  { value: "Insoluble oxalate", label: "Insoluble Oxalate" },
  { value: "Iron", label: "Iron" },
  { value: "Isoleucine", label: "Isoleucine" },
  { value: "Isorhamnetin", label: "Isorhamnetin" },
  { value: "Kaempferol", label: "Kaempferol" },
  { value: "Lauric", label: "Lauric" },
  { value: "Lead", label: "Lead" },
  { value: "Leucine", label: "Leucine" },
  { value: "Lignoceric", label: "Lignoceric" },
  { value: "Linoleic", label: "Linoleic" },
  { value: "Lithium", label: "Lithium" },
  { value: "Lutein", label: "Lutein" },
  { value: "Luteolin", label: "Luteolin" },
  { value: "Lycopene", label: "Lycopene" },
  { value: "Lysine", label: "Lysine" },
  { value: "Magnesium", label: "Magnesium" },
  { value: "Malic Acid", label: "Malic Acid" },
  { value: "Maltose", label: "Maltose" },
  { value: "Manganese", label: "Manganese" },
  { value: "Menaquinones (K2)", label: "Menaquinones (K2)" },
  { value: "Mercury", label: "Mercury" },
  { value: "Methionine", label: "Methionine" },
  { value: "Moisture", label: "Moisture" },
  { value: "Molybdenum", label: "Molybdenum" },
  { value: "Myricetin", label: "Myricetin" },
  { value: "Myristic", label: "Myristic" },
  { value: "Myristoleic", label: "Myristoleic" },
  { value: "Naringenin", label: "Naringenin" },
  { value: "Nervonic", label: "Nervonic" },
  { value: "Net Carbohydrates", label: "Net Carbohydrates" },
  { value: "Niacin_B3", label: "Niacin B3" },
  { value: "Nickel", label: "Nickel" },
  { value: "O-Coumaric acid", label: "O-Coumaric Acid" },
  { value: "Oleic", label: "Oleic" },
  { value: "Palmitic", label: "Palmitic" },
  { value: "Palmitoleic", label: "Palmitoleic" },
  { value: "Pantothenic acid_B5", label: "Pantothenic Acid B5" },
  { value: "P-Coumaric acid", label: "P-Coumaric Acid" },
  { value: "Phenylalanine", label: "Phenylalanine" },
  { value: "Phosphorus", label: "Phosphorus" },
  { value: "Phylloquinones_K1", label: "Phylloquinones K1" },
  { value: "Phytate", label: "Phytate" },
  { value: "Potassium", label: "Potassium" },
  { value: "Proline", label: "Proline" },
  { value: "Protein", label: "Protein" },
  { value: "Protocatechuic acid", label: "Protocatechuic Acid" },
  { value: "Quercetin", label: "Quercetin" },
  { value: "Quercetin-3-O-rutinoside", label: "Quercetin-3-O-Rutinoside" },
  { value: "Quercetin-3-β-D-glucoside", label: "Quercetin-3-β-D-Glucoside" },
  { value: "Quercetin-3-β-galactoside", label: "Quercetin-3-β-Galactoside" },
  { value: "Quinic Acid", label: "Quinic Acid" },
  { value: "Raffinose Oligosaccharides", label: "Raffinose Oligosaccharides" },
  { value: "Resveratrol", label: "Resveratrol" },
  { value: "Retinol", label: "Retinol" },
  { value: "Riboflavin_B2", label: "Riboflavin B2" },
  { value: "Selenium", label: "Selenium" },
  { value: "Serine", label: "Serine" },
  { value: "Sinapinic acid", label: "Sinapinic Acid" },
  { value: "Sodium", label: "Sodium" },
  { value: "Soluble dietary fibre", label: "Soluble Dietary Fibre" },
  { value: "Soluble oxalate", label: "Soluble Oxalate" },
  { value: "Stachyose Oligosaccharides", label: "Stachyose Oligosaccharides" },
  { value: "Stearic", label: "Stearic" },
  { value: "Stigmasterol Phytosterols", label: "Stigmasterol Phytosterols" },
  { value: "Succinic Acid", label: "Succinic Acid" },
  { value: "Sucrose", label: "Sucrose" },
  { value: "Syringic acid", label: "Syringic Acid" },
  { value: "Tartaric Acid", label: "Tartaric Acid" },
  { value: "Thiamine_B1", label: "Thiamine B1" },
  { value: "Threonine", label: "Threonine" },
  { value: "Tocopherol_Equivalent", label: "Tocopherol Equivalent" },
  { value: "Total Ascorbic Acid/Vitamin C", label: "Total Ascorbic Acid/Vitamin C" },
  { value: "Total available CHO", label: "Total Available CHO" },
  { value: "Total B6", label: "Total B6" },
  { value: "Total Carotenoids", label: "Total Carotenoids" },
  { value: "Total dietary fibre", label: "Total Dietary Fibre" },
  { value: "Total Fat", label: "Total Fat" },
  { value: "Total folates_B9", label: "Total Folates B9" },
  { value: "Total Free Sugars", label: "Total Free Sugars" },
  { value: "Total monounsaturated fatty acids", label: "Total Monounsaturated Fatty Acids" },
  { value: "Total oxalate", label: "Total Oxalate" },
  { value: "Total polyphenols", label: "Total Polyphenols" },
  { value: "Total polyunsaturated fatty acids", label: "Total Polyunsaturated Fatty Acids" },
  { value: "Total Saponin", label: "Total Saponin" },
  { value: "Total saturated fatty acids", label: "Total Saturated Fatty Acids" },
  { value: "Total Starch", label: "Total Starch" },
  { value: "Tryptophan", label: "Tryptophan" },
  { value: "Tyrosine", label: "Tyrosine" },
  { value: "Valine", label: "Valine" },
  { value: "Vanillic acid", label: "Vanillic Acid" },
  { value: "Verbascose Oligosaccharides", label: "Verbascose Oligosaccharides" },
  { value: "Zeaxanthin", label: "Zeaxanthin" },
  { value: "Zinc", label: "Zinc" },
  { value: "β-Sitosterol", label: "β-Sitosterol" },
  { value: "None", label: "None" },
];



export const therapeuticOptions = [
  { value: "PCOS", label: "PCOS" },
  { value: "Diabetes", label: "Diabetes" },
  { value: "Dyslipidemia", label: "Dyslipidemia" },
  { value: "Heart disease", label: "Heart Disease" },
  { value: "Liver disease", label: "Liver Disease" },
  { value: "Kidney disease", label: "Kidney Disease" },
  { value: "Others", label: "Others" },
  { value: "None", label: "None" },
];

export const triglycerideOptions = [
  { value: "Long chain", label: "Long Chain" },
  { value: "Medium chain", label: "Medium Chain" },
  { value: "Short chain", label: "Short Chain" },
  { value: "None", label: "None" },
];

export const quantityvalueOptions = [
  { value: "0.1", label: "0.1" },
  { value: "0.15", label: "0.15" },
  { value: "0.2", label: "0.2" },
  { value: "0.25", label: "0.25" },
  { value: "0.3", label: "0.3" },
  { value: "0.35", label: "0.35" },
  { value: "0.4", label: "0.4 " },
  { value: "0.45", label: "0.45" },
  { value: "0.5", label: "0.5" },
  { value: "0.55", label: "0.55" },
  { value: "0.6", label: "0.6" },
  { value: "0.65", label: "0.65" },
  { value: "0.7", label: "0.7" },
  { value: "0.75", label: "0.75" },
  { value: "0.8", label: "0.8" },
  { value: "0.85", label: "0.85" },
  { value: "0.9", label: "0.9" },
  { value: "0.95", label: "0.95" },
  { value: "1", label: "1" },
  { value: "1.5", label: "1.5" },
  { value: "2", label: "2" },
  { value: "2.5", label: "2.5" },
  { value: "3", label: "3" },
  { value: "3.5", label: "3.5" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "45", label: "45" },
  { value: "50", label: "50" },
  { value: "60", label: "60" },
  { value: "70", label: "70" },
  { value: "80", label: "80" },
  { value: "90", label: "90" },
  { value: "100", label: "100" },
  { value: "150", label: "150" },
  { value: "200", label: "200" },
  { value: "250", label: "250" },
  { value: "300", label: "300" },
  { value: "350", label: "350" },
  { value: "400", label: "400" },
  { value: "450", label: "450" },
  { value: "500", label: "500" },
  { value: "600", label: "600" },
  { value: "700", label: "700" },
  { value: "800", label: "800" },
  { value: "900", label: "900" },
  { value: "1000", label: "1000" },
  { value: "1500", label: "1500" },
  { value: "2000", label: "2000" },
];
