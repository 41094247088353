import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    countresearcherdashboard,
    fetchResearcherData,
} from "../../Service/ApiServices";
import { useAuth } from "../../Service/Context/AuthProvider";
import Card from "../../Utils/Card/Card";
const ResearchToDo = lazy(() =>
  import("../../Page/Research/ResearchToDo/ResearchToDo")
);
const ResearchRework = lazy(() =>
  import("../../Page/Research/ResearchRework/ResearchRework")
);
const ResearchInProgress = lazy(() =>
  import("../../Page/Research/ResearchInProgress/ResearchInProgress")
);
const ResearchReadyToReview = lazy(() =>
  import("../../Page/Research/ResearchReadyToReview/ResearchReadyToReview")
);

function ResearcherDashboard() {
  const dispatch = useDispatch();
  const {  countdata, dataresearch } = useSelector(
    (state) => state.researcher
  );
  const { userData } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const statekey = location?.state;
  const [activeIndex, setActiveIndex] = useState(statekey ? statekey?.card : 1);

  useEffect(() => {
    if (statekey) {
      navigate(location.pathname, { replace: true });
    }
  }, [statekey]);
  useEffect(() => {
    if (userData) {
      dispatch(
        countresearcherdashboard({
          token: userData.token,
          navigate
        })
      );
      dispatch(fetchResearcherData(userData.userId));
    }
  }, [userData, dispatch]);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const checkActive = (index, className) =>
    activeIndex === index ? className : "";

  return (
    <div>
      <>
        <div className="row main-cards" style={{gap:"2.5%"}}>
          <div
            className={`col-md ${checkActive(1, "dashboard-active")}`}
            onClick={() => handleClick(1)}
          >
            <Card
              text="Recipes to Do"
              img="pending"
              value={countdata?.data?.to_do || 0} 
            />
          </div>
          <div
            className={`col-md ${checkActive(2, "dashboard-active")}`}
            onClick={() => handleClick(2)}
          >
            <Card
              text="Recipes to Rework"
              img="source_notes"
              value={countdata?.data?.rework || 0} 
            />
          </div>
          <div
            className={`col-md ${checkActive(3, "dashboard-active")}`}
            onClick={() => handleClick(3)}
          >
            <Card
              text="Recipes in Progress"
              img="pending_actions"
              value={countdata?.data?.in_progress || 0} 
            />
          </div>
          <div
            className={`col-md ${checkActive(4, "dashboard-active")}`}
            onClick={() => handleClick(4)}
          >
            <Card
              text="Ready to Review"
              img="preliminary"
              value={countdata?.data?.ready_to_review || 0} 
            />
          </div>
        </div>
            </>

      <div> 
        <Suspense fallback={<div>Loading...</div>}>
          {activeIndex === 1 && <ResearchToDo researcherdata={dataresearch?.data?.user_approved} />}
          {activeIndex === 2 && <ResearchRework researcherdata={dataresearch?.data?.user_approved} />}
          {activeIndex === 3 && <ResearchInProgress researcherdata={dataresearch?.data?.user_approved} />}
          {activeIndex === 4 && <ResearchReadyToReview researcherdata={dataresearch?.data?.user_approved} />}
        </Suspense>
      </div>
    </div>
  );
}

export default ResearcherDashboard;
