import React, { lazy, Suspense, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AdimnExcel from "../../Page/Admin/AdimnExcel/AdimnExcel";
import { AdminCountDashboard } from "../../Service/ApiServices";
import { useAuth } from "../../Service/Context/AuthProvider";
import Card from "../../Utils/Card/Card";
const AdminNumResearchers = lazy(() =>
  import("../../Page/Admin/AdminNumResearchers/AdminNumResearchers")
);
const AdminNumQa = lazy(() => import("../../Page/Admin/AdminNumQa/AdminNumQa"));
const AdminNumRecipe = lazy(() =>
  import("../../Page/Admin/AdminNumRecipe/AdminNumRecipe")
);
const AdminNum = lazy(() => import("../../Page/Admin/AdminNum/AdminNum"));

function AdminDashboard() {
  
  const {userData} = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [fooduploadreducer, fooduploadforceUpdate] = useReducer(x => x + 1, 0)
  const statekey = location?.state;
  const [activeIndex, setActiveIndex] = useState(
    statekey ? Number(statekey?.card) : 1
  );
  useEffect(() => {
    if (statekey) {
      navigate(location.pathname, { replace: true });
    }
  }, [statekey]);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const checkActive = (index, className) =>
    activeIndex === index ? className : "";

  const dispatch = useDispatch();
  const { data  } = useSelector((state) => state.admin);

  useEffect(() => {
    if (userData) {
      dispatch(AdminCountDashboard({token:userData.token,navigate}));
    }
  }, [dispatch, userData]);

  const handleNavigation = () => {
    navigate("/vfs/usda-master"); 
  };
  return (
    <div className="admindashboard">
      <div className="row  main-cards" style={{gap:"2.5%"}}>
        <div
          className={`col-md ${checkActive(1, "dashboard-active")}`}
          onClick={() => handleClick(1)}
        >
          <Card
            text="Total No of Researchers"
            img="group"
            value={data?.research_count}
          />
        </div>
        <div
          className={`col-md ${checkActive(2, "dashboard-active")}`}
          onClick={() => handleClick(2)}
        >
          <Card text="Total No of QA" img="group" value={data?.qa_count} />
        </div>
        <div
          className={`col-md ${checkActive(3, "dashboard-active")}`}
          onClick={() => handleClick(3)}
        >
          <Card
            text="Total No of Recipes Approved"
            img="grocery"
            value={data?.task_approved_count}
          />
        </div>
        <div
          className={`col-md ${checkActive(4, "dashboard-active")}`}
          onClick={() => handleClick(4)}
        >
          <Card
            text="Total No of Admin"
            img="group"
            value={data?.admin_count}
          />
        </div>
      </div>
      <div className="displaytable">
        <div className="admin-displaytable " >
          <Suspense fallback={<div>Loading...</div>}>
            {activeIndex === 1 && <AdminNumResearchers fooduploadreducer={fooduploadreducer} />}
            {activeIndex === 2 && <AdminNumQa />}
            {activeIndex === 3 && <AdminNumRecipe />}
            {activeIndex === 4 && <AdminNum />}
          </Suspense>
        </div>
        <div className="admin-displayexcel">
        <div className="d-flex justify-content-end">
        <div className="submit-btn py-2 px-2  mb-4 d-flex justify-content-center" onClick={handleNavigation} style={{ cursor: "pointer" }}>
          Master Ingredients
          </div>
        </div>
          <AdimnExcel fooduploadforceUpdate={fooduploadforceUpdate} />
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
