import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAdminViewList } from "../../../../Service/ApiServices";
import { useAuth } from "../../../../Service/Context/AuthProvider";
import AdminViewTables from "../../../../Utils/Table/AdminViewTables";
import StatusCode from "../../../../Utils/StatusCode";

function ViewAdminResearcher() {
  const { userData } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tablevalue,fetchAdminViewListstatus } = useSelector((state) => state.admin);

  const key = useParams();
  const decryptData = (encryptedData) => {
    try {
      const decodedData = atob(encryptedData);
      const sanitizedData = decodedData.replace(/[^\x20-\x7E]/g, "");
      return sanitizedData;
    } catch (error) {
      return null;
    }
  };

  const statekeyvalue = decryptData(key?.datavalue);

  useEffect(() => {
    if (statekeyvalue && userData) {
      const role = "researcher";
      dispatch(
        fetchAdminViewList({
          userid: statekeyvalue,
          role,
          token: userData.token,
          navigate
        })
      );
    }
  }, [statekeyvalue, dispatch, userData]);

  if (fetchAdminViewListstatus === StatusCode.LOADING) {
    return <h4 className='text-center mt-5'><div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></h4>
}
// if (fetchAdminViewListstatus === StatusCode.ERROR) {
//     return <div className='text-center mt-5'><span class="  fs-1">
//         No Data Available
//     </span></div>
// }
 
  return (
    <AdminViewTables
      data={tablevalue?.data?.tasks}
      researcherName={tablevalue?.data?.user_fullname}
      qaName={tablevalue?.data?.qa_name}
      researcherid={statekeyvalue}
      visible={true}
      role="researcher"
      card={1}
    />
  );
}

export default ViewAdminResearcher;
