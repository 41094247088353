import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AdminpostEditUser,
  FetchEditUser,
  fetchCountrycodesandqadetails,
  fetchZipcode,
} from "../../../../Service/ApiServices";
import { useAuth } from "../../../../Service/Context/AuthProvider";
import "../../../../Style/Admin.scss";
import Button from "../../../../Utils/Buttons/Button";
import Select from "react-select";
import Model from "../../../../Utils/Model/Model";
import CancelModel from "../../../../Utils/Model/AdminModel/CancelModel";
const EditUser = () => {
  const key = useParams();
  let card;
  const decryptData = (encryptedData) => {
    try {
      const decodedData = atob(encryptedData);
      const sanitizedData = decodedData.replace(/[^\x20-\x7E]/g, "");
      const parts = sanitizedData.split("//");
      card = parts[1];

      return parts[0];
    } catch (error) {
      console.error("Decryption failed", error);
      return null;
    }
  };

  const statekeyvalue = decryptData(key?.datavalue);

  const dispatch = useDispatch();
  const { qaList, fetchedituser } = useSelector(
    (state) => state.admin
  );
  const { userData } = useAuth();
  const [ischange, setischange] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    dob: "",
    age: "",
    gender: "Select",
    email: "",
    contactNumber: "",
    zipCode: "",
    state: "",
    city: "",
    address: "",
    country: "",
    role: "Select",
    qa: "Select",
    countrycodevalue: "+91",
  });

  const [errorMessages, setErrorMessages] = useState({
    fullName: "",
    dob: "",
    gender: "",
    email: "",
    contactNumber: "",
    zipCode: "",
    state: "",
    address: "",
    country: "",
    role: "",
    qa: "",
  });
  const navigate = useNavigate();
  
  const [modalView, setModalView] = useState(false);


  useEffect(() => {
    if (statekeyvalue && userData) {
      dispatch(
        FetchEditUser({ statekey: statekeyvalue, token: userData.token,navigate })
      );
    }
  }, [statekeyvalue, userData, dispatch]);

  useEffect(() => {
    setFormData({
      fullName: fetchedituser?.user_fullname ?? "",
      email: fetchedituser?.user_email ?? "",
      qa: fetchedituser?.assigned_qa ?? "",
      contactNumber: fetchedituser?.phone_number ?? "",
      gender: fetchedituser?.gender ?? "",
      dob: fetchedituser?.dob ?? "",
      age: fetchedituser?.age ?? "",
      zipCode: fetchedituser?.pin_code ?? "",
      city: fetchedituser?.city ?? "",
      state: fetchedituser?.state ?? "",
      country: fetchedituser?.country ?? "",
      address: fetchedituser?.address ?? "",
      countrycodevalue: fetchedituser?.ph_code ?? "+91",
      role: fetchedituser?.role ?? "",
      userid: statekeyvalue ?? "",
    });
  }, [fetchedituser]);
  useEffect(() => {
    if (userData) {
      dispatch(fetchCountrycodesandqadetails({ token: userData.token,navigate }));
    }
  }, [dispatch, userData]);


  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const countryCodes = [{ country: 'India', code: '+91' }, { country: 'USA', code: '+1' }]
  const options = countryCodes.map((item) => ({
    value: item.code,
    label: `${item.country} (${item.code})`,
  }));

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]/g;
    const sanitizedValue = value.replace(emojiRegex, '');
    let updatedFormData = { ...formData };
  
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [name]: "",
    }));
  
    if (name === "fullName") {
      if (/^[a-zA-Z ]*$/.test(sanitizedValue)) {
        updatedFormData = {
          ...updatedFormData,
          [name]: sanitizedValue,
        };
      }
    } else if (name === "dob") {
      updatedFormData = {
        ...updatedFormData,
        [name]: sanitizedValue,
      };
      const dob = new Date(sanitizedValue);
      if (!isNaN(dob.getTime())) {
        const today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < dob.getDate())
        ) {
          age--;
        }
        if (age >= 14 && age <= 60) {
          updatedFormData = {
            ...updatedFormData,
            age: age,
          };
        } else {
          setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            dob: `Year must be between ${today.getFullYear() - 60
              } and ${today.getFullYear() - 14} years.`,
          }));
        }
      } else {
        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          dob: "Please enter a valid date.",
        }));
        updatedFormData.age = "";
      }
    } else if (name === "contactNumber") {
      let updatedValue = sanitizedValue.replace(/[^0-9]/g, "");
      updatedFormData = {
        ...updatedFormData,
        [name]: updatedValue,
      };
    } else if (name === "zipCode") {
      let updatedValue = sanitizedValue.replace(/[^0-9]/g, "");
      updatedValue = updatedValue.slice(0, 6);
      const maxLength = selectedCountryCode === "+1" ? 5 : 6;
      updatedFormData = {
        ...updatedFormData,
        [name]: updatedValue,
      };

        if (updatedValue.length === maxLength) {
          fetchAddressDetails(updatedValue);
        }
  
     
        if (updatedValue.length < maxLength) {
          updatedFormData = {
            ...updatedFormData,
            [name]: updatedValue,
            country: "",
            state: "",
            city: "",
          };
          setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            zipCode: "Please enter a valid Zipcode.",
          }));
        } else {
          updatedFormData = {
            ...updatedFormData,
            [name]: updatedValue,
          };
        }
    } else if (name === "role") {
      updatedFormData = {
        ...updatedFormData,
        [name]: sanitizedValue,
        qa: "Select",
      };
    } else {
      updatedFormData = {
        ...updatedFormData,
        [name]: sanitizedValue,
      };
    }
  
    setFormData(updatedFormData);
  };
  



  const fetchAddressDetails = async (zipcode) => {

    try {

      const response = await dispatch(fetchZipcode({ token: userData.token, zipcode,navigate })).unwrap();
      const responseData = response;
      

      if (responseData) {
        const addressInfo = responseData;
        setFormData((prevFormData) => ({
          ...prevFormData,
          state: addressInfo?.state,
          country: addressInfo?.country,
          city: addressInfo?.city,
        }));

        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          zipCode: "",
          country: "",
          state: "",

        }));

      }
      if (responseData.status === "error") {
        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          zipCode: responseData.message,
        }));
      }
    } catch (error) {
      toast.error("Please enter the Valid Zip Code", {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "toast-top-right",
      });
    }
  };
  const validateForm = () => {
    const errors = {};
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    const vizzhyEmailRegex = /^[a-zA-Z0-9._%+-]+@vizzhy\.com$/;

    if (!formData.fullName) {
      errors.fullName = "Please enter your full name.";
    }

    if (!formData.dob) {
      errors.dob = "Please enter your date of birth.";
    } else {
      const dob = new Date(formData.dob);
      if (!isNaN(dob.getTime())) {
        const today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < dob.getDate())
        ) {
          age--;
        }
        if (age < 14 || age > 60) {
          errors.dob = "Age must be between 14 and 60 years.";
        }
      } else {
        errors.dob = "Please enter a valid date.";
      }
    }

    if (!formData.gender || formData.gender === "Select") {
      errors.gender = "Please select your gender.";
    }

    if (!formData.email) {
      errors.email = "Please enter your email address.";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Please enter a valid email address.";
    } else if (!vizzhyEmailRegex.test(formData.email)) {
      errors.email = "Only vizzhy email addresses are accepted.";
    }

    if (!formData.contactNumber) {
      errors.contactNumber = "Please enter your contact number.";
    } else if (formData.contactNumber.length < 10) {
      errors.contactNumber = "Please enter a valid contact number.";
    }

    if (!formData.zipCode || formData.zipCode.length < 6) {
      errors.zipCode = "Please enter your zip code.";
    }

    if (!formData.state) {
      errors.state = "Please enter your state.";
    }

    if (!formData.address) {
      errors.address = "Please enter your address.";
    }

    if (!formData.country) {
      errors.country = "Please enter your country.";
    }

    if (!formData.role || formData.role === "Select") {
      errors.role = "Please select a role.";
    }

    if (formData.role === "research") {
      if (!formData.qa || formData.qa === "Select") {
        errors.qa = "Please select a QA option.";
      }
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();

    setErrorMessages(errors);

    if (Object.keys(errors).length !== 0) {
      return;
    }

    try {
      const resultAction = await dispatch(AdminpostEditUser({
        formData: {
          "user_fullname": formData.fullName,
          "user_email": formData.email,
          "role": formData.role,
          "assigned_qa": formData.qa === "Select" ? "" : formData.qa,
          "ph_code": formData.countrycodevalue,
          "phone_number": formData.contactNumber,
          "gender": formData.gender,
          "dob": formData.dob,
          "age": formData.age,
          "pin_code": formData.zipCode,
          "city": formData.city,
          "state": formData.state,
          "country": formData.country,
          "address": formData.address,
          "userId": statekeyvalue
        },
        token: userData.token,
        navigate
      }));

      if (AdminpostEditUser.fulfilled.match(resultAction)) {
        toast.success("User updated successfully", {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          className: "toast-top-right",
        });
        navigate("/vfs/admin-dashboard", { state: { card } });
      } else {
        throw new Error(resultAction.payload);
      }
    } catch (error) {
      toast.error("An error occurred during update", {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "toast-top-right",
      });
    }
  };

  const getValue = () => {
    return (
      options.find((option) => option.value === formData.countrycodevalue) ||
      null
    );
  };
  const SingleValue = ({ data }) => (
    <div className="position-absolute ps-3 fs-6">{data.value}</div>
  );


  const calculateMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(today.setFullYear(today.getFullYear() - 14));
    return maxDate.toISOString().split("T")[0];
  };




  const handleCancel = () => {
    setModalView({ show: true, type: "cancel" });
  };

  const handleEnroll = () => {
    const errors = validateForm();

    setErrorMessages(errors);

    if (Object.keys(errors).length !== 0) {
      return;
    }
    setModalView({ show: true, type: "save" });
    
  };

  const closeModal = () => {
    setModalView({ show: false, type: null });
  };

  const getModalContent = () => {
    switch (modalView.type) {
      case "cancel":
        return (
          <CancelModel
            card={card}
            message={"Are you sure you want to Cancel?"}
            iconName={"cancel"}
            closeModalView={closeModal}
            condition={"Edit"}
          />
        );
      case "save":
        return (
          <CancelModel
            card={card}
            message={"Are you sure you want to Save the changes?"}
            iconName={"bookmark_added"}
            condition={"Edit"}
            formData={formData}
            closeModalView={closeModal}
          />
        );
      default:
        return null;
    }
  };




  return (
    <div className="adduser w-100">
      <div className="mt-3 padding-margin">
        <h3 className="fw-bolder">Edit</h3>
        <div className="mt-5 row w-100 button-media">
          <div className="col-md-2 fw-bold text-nowrap mt-3">
            Personal Details
          </div>
          <div className="col-md-3 ">
            <div>
              <div className=" mt-3 input-container-adduser">
                <small className=" fw-bold-2">Full Name</small>{" "}
                <small className="red-star">*</small>
                <input
                  autoComplete="off"
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="form-control  border-secondary mt-2"
                  placeholder="Enter"
                />
                <small className="error-message">
                  {errorMessages.fullName}
                </small>
              </div>
              <div className="mt-3 input-container-adduser">
                <small className="fw-bold-2">Date of Birth</small>{" "}
                <small className="red-star">*</small>
                <div className="d-flex mt-2">
                  <input
                    autoComplete="off"
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleChange}
                    className="form-control border-secondary me-3 w-75"
                    placeholder="Enter"
                    max={calculateMaxDate()}
                  />
                  <input
                    autoComplete="off"
                    className="form-control border-secondary  w-25"
                    placeholder="Age"
                    name="age"
                    value={formData.age}
                    readOnly
                  />
                </div>
                <small className="error-message">{errorMessages.dob}</small>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <div className=" mt-3 input-container-adduser">
                <small className="fw-bold-2">Gender</small>{" "}
                <small className="red-star">*</small>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="form-select   border-secondary mt-2"
                >
                  <option value="Select" selected disabled>
                    Select
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <small className="error-message">{errorMessages.gender}</small>
              </div>
              <div className="mt-3 input-container-adduser">
                <small className="fw-bold-2">Email</small>{" "}
                <small className="red-star">*</small>
                <input
                  autoComplete="off"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control border-secondary mt-2"
                  placeholder="Enter Email Address"
                  disabled
                />
                <small className="error-message">{errorMessages.email}</small>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className=" mt-3 input-container-adduser">
              <small className=" fw-bold-2">Contact Number</small>{" "}
              <small className="red-star">*</small>
              <div className="d-flex mt-2">
                <div className="w-50">
                  <Select
                    value={getValue()}
                    onChange={(selectedOption) => {
                      const countryCode = selectedOption ? selectedOption.value : "";
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        countrycodevalue: countryCode,
                        zipCode: "",
                        country: "",
                        state: "",
                        city: ""
                      }));
                      setErrorMessages((prevErrorMessages) => ({
                        ...prevErrorMessages,
                        zipCode: "",
                      }));
                      setSelectedCountryCode(countryCode);
                    }}
                    options={options}
                    className="formdata-selectcountary position-relative border-secondary"
                    classNamePrefix="formdata-selectcountary"
                    isSearchable
                    components={{ SingleValue }}
                    name="countrycode"
                  />
                </div>
                <input
                  autoComplete="off"
                  type="text"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  className="form-control border-secondary ms-3 w-75"
                  placeholder="Enter Contact no."
                  maxLength={10}
                />
              </div>

              <small className="error-message">
                {errorMessages.contactNumber}
              </small>
            </div>
          </div>
        </div>

        <div className="mt-5 row w-100">
          <div className="col-md-2 fw-bold mt-3">Address</div>
          <div className="col-md-3">
            <div>
              <div className="mt-3 input-container-adduser">
                <small className="fw-bold-2">Zip Code</small>{" "}
                <small className="red-star">*</small>
                <div className="search-zipcode">
                  <input
                    autoComplete="off"
                    type="text"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                    className="form-control border-secondary mt-2"
                    placeholder="Enter"
                    maxLength={selectedCountryCode === "+1" ? 5 : 6}
                  />
                  <span className="material-symbols-outlined search-icon search-zipcode-icon">
                    search
                  </span>
                </div>
                <small className="error-message">{errorMessages.zipCode}</small>
              </div>
              <div className="mt-3">
                <small className="fw-bold-2">City</small>
                <input
                  autoComplete="off"
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="form-control border-secondary mt-2"
                  placeholder="Enter"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <div className="mt-3 input-container-adduser">
                <small className="fw-bold-2">State</small>{" "}
                <small className="red-star">*</small>
                <input
                  autoComplete="off"
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="form-control border-secondary mt-2"
                  placeholder="Enter"
                  disabled
                />
                <small className="error-message">{errorMessages.state}</small>
              </div>

              <div className=" mt-3 input-container-adduser">
                <small className=" fw-bold-2">Country</small>{" "}
                <small className="red-star">*</small>
                <input
                  autoComplete="off"
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="form-control border-secondary mt-2"
                  placeholder="Enter"
                  disabled
                />
                <small className="error-message">{errorMessages.country}</small>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="mt-3 input-container-adduser">
              <small className=" fw-bold-2">Address</small>{" "}
              <small className="red-star">*</small>
              <input
                autoComplete="off"
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="form-control border-secondary mt-2"
                placeholder="Door no/Floor/Building Name"
              />
              <small className="error-message">{errorMessages.address}</small>
            </div>
          </div>
        </div>

        <div className="mt-5 row w-100">
          <div className="col-md-2 fw-bold mt-3">Assignee</div>
          <div className="col-md-3">
            <div className=" mt-3 input-container-adduser">
              <small className=" fw-bold-2">Role</small>{" "}
              <small className="red-star">*</small>
              <select
                name="role"
                value={formData.role}
                onChange={handleChange}
                disabled
                className="form-select  border-secondary mt-2"
              >
                <option value="Select" selected disabled>
                  Select
                </option>
                <option value="admin">Admin</option>
                <option value="researcher">Research</option>
                <option value="qa">QA</option>
              </select>
              <small className="error-message">{errorMessages.role}</small>
            </div>
          </div>
          {formData.role === "researcher" ? (
            <div className="col-md-3">
              <div className=" mt-3 input-container-adduser">
                <small className=" fw-bold-2">QA</small>{" "}
                <small className="red-star">*</small>
                <select
                  name="qa"
                  value={formData.qa}
                  onChange={handleChange}
                  className="form-select  border-secondary mt-2"
                >
                  <option value="Select" selected disabled>
                    Select
                  </option>
                  {qaList.map((QA, index) => (
                    <option key={index} value={QA.user_id}>
                      {QA.user_fullname}{" "}
                      <span>{`(Allocated researcher):${QA.count}`}</span>
                    </option>
                  ))}
                </select>
                <small className="error-message">{errorMessages.qa}</small>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-3"></div>
        </div>
        <div className="mt-5 row w-100">
         
          <div className="mb-3 d-flex justify-content-between align-items-center button-media link-tag">
            <div className="button cancel-btn px-5 p-2 admin-view" onClick={handleCancel}>
              <Button value="Cancel" />
            </div>
            <div className="button submit-btn px-5 p-2 cursor-pointer" onClick={handleEnroll}>
              <Button value="Save" />
            </div>
            
          </div>


        </div>
      </div>
      {
        <Model
        data="modal-sm"
        showModal={modalView.show}
        content={getModalContent()}
        closeModal={closeModal}
      />
      }
    </div>
  );
};
export default EditUser;
