import React from 'react';

// Helper function to capitalize the first letter of each word
const capitalizeWords = (str) => {
    return str.replace(/_/g, ' ') // Replace underscores with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

// Function to map Nova category to its corresponding number
function getNovaCategory(nova) {
    switch (nova) {
        case "Unprocessed":
            return 1;
        case "Processed culinary ingredients":
            return 2;
        case "Processed":
            return 3;
        case "Ultra processed":
            return 4;
        default:
            return "-";
    }
}

function ViewParameter({ closeModal, qa_id, rfn_id, status, datavalue }) {
    const { macro, micro, score } = datavalue?.nutrition || {};
  
    // Helper function to render each category and its nutrients
    const renderNutrientCategory = (categoryName, categoryData) => {
        // console.log("123456789",categoryName, categoryData)
        const hasValidNutrients = Object.entries(categoryData).some(([nutrient, details]) =>
            details?.value !== undefined && details?.value !== null
        );

        if (!hasValidNutrients) return null; // Skip rendering if no valid nutrients

        return (
            <div key={categoryName} className="nutrient-category mb-4">
                <h6 className="fw-bold">{categoryName === 'Macro' ? '' :capitalizeWords(categoryName)}</h6>
                <div className='row rounded viewpara-css my-4'>
                    {Object.entries(categoryData).map(([nutrient, details]) => {
                        if (details?.value !== undefined && details?.value !== null) {
                            return (
                                <div className='col-sm-3 py-2 d-flex justify-content-between align-items-center' key={nutrient}>
                                    <span style={{ color: "#787878" }}>{capitalizeWords(nutrient)} </span>
                                    <span className='text-nowrap fw-bold'>{parseFloat(details.value).toFixed(2)}{details?.unit}</span>
                                </div>
                            );
                        }
                        return null; // Skip if value is undefined or null
                    })}
                </div>
            </div>
        );
    };

    // Render the score section
    const renderScore = (scoreData) => {
        const hasValidScore = Object.entries(scoreData).some(([key, value]) => value !== undefined && value !== null);

        if (!hasValidScore) return null; // Skip rendering if no valid score

        return (
            <div className="score-category my-4">
                <h6 className="fw-bold fs-5 mb-3">Nutrition Score</h6>
                <div className='row rounded viewpara-css'>
                    {Object.entries(scoreData).map(([key, value]) => {
                        if (value !== undefined && value !== null) {
                            // Check if the key is 'nova' to display its category
                            if (key === 'nova') {
                                return (
                                    <div className='col-sm-3 py-2 d-flex justify-content-between align-items-center' key={key}>
                                        <span style={{ color: "#787878" }}>{capitalizeWords(key)}:</span>
                                        <span className='fw-bold'>{getNovaCategory(value)}</span> {/* Use getNovaCategory to convert value */}
                                    </div>
                                );
                            }

                            return (
                                <div className='col-sm-3 py-2 d-flex justify-content-between align-items-center' key={key}>
                                    <span>{capitalizeWords(key)}</span>
                                    <span className='fw-bold'>{typeof value === 'number' ? value.toFixed(2) : value}</span>
                                </div>
                            );
                        }
                        return null; // Skip if value is undefined or null
                    })}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title fw-bold fs-5">Parameters</h5>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
            </div>
            <div className="view-parameter mt-4 container-fluid">
                {score && renderScore(score)}
                {macro &&
                    <div>
                        <div className='fw-bold fs-5'>Macronutrients</div>
                        {
                            renderNutrientCategory('Macro', macro?.other)
                        }
                    </div>
                }
                {micro &&
                    <div>
                        <div className='fw-bold fs-5 mb-2'>Micronutrients</div>
                        {micro && Object.entries(micro).map(([categoryName, categoryData]) =>
                            renderNutrientCategory(categoryName, categoryData)
                        )}
                    </div>
                }

            </div>
        </div>
    );
}

export default ViewParameter;
