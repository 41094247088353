import { configureStore } from "@reduxjs/toolkit";
import AdminSlice from "./AdminSlice/AdminSlice";
import ResearcherSlice from "./ResearcherSlice/ResearcherSlice";
import QaSlice from "./QaSlice/QaSlice";
import UserSlice from "./UserSlice/UserSlice";

const store = configureStore({
    reducer:{
        admin: AdminSlice,
        researcher: ResearcherSlice,
        qaSlice: QaSlice,
        userSlice: UserSlice
    }
})

export default store