import React from 'react'
import '../../Style/Admin.scss';
function Card({ value, text, img }) {
    return (
        <div className='card-main-div'>
            <div className="card shadow-sm border-0 dashboard-cards curser-pointer" >
                <div className="card-body">
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <div className='fw-bold fs-4'>{value}</div>
                            <small className='card-data fw-bold'>{text}</small>
                        </div>
                        <div className='card-icon p-3 rounded'>
                            <span className="material-symbols-outlined fs-2">{img}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card
