
import { createSlice } from '@reduxjs/toolkit';
import StatusCode from '../../../Utils/StatusCode';
import {ViewQafetchFoodDetails, fetchQaTasksTable, getQaNumber, uploadQaFoodDetails } from '../../ApiServices';


const QaSlice = createSlice({
    name: 'products',
    initialState: {
        data: [],
        status: 'idle',
        error: null,
        datacount:[],
        datavalue: [],
        dataUpdateModel:[],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchQaTasksTable.pending, (state) => {
                state.fetchQaTasksTablestatus = StatusCode.LOADING;
            })
            .addCase(fetchQaTasksTable.fulfilled, (state, action) => {
                state.fetchQaTasksTablestatus = StatusCode.IDLE;
                state.data = action.payload;
            })
            .addCase(fetchQaTasksTable.rejected, (state) => {
                state.fetchQaTasksTablestatus = StatusCode.ERROR;
            })
            
            .addCase(getQaNumber.pending, (state) => {
                state.status = StatusCode.LOADING;
            })
            .addCase(getQaNumber.fulfilled, (state, action) => {
                state.status = StatusCode.IDLE;
                state.datacount = action.payload;
            })
            .addCase(getQaNumber.rejected, (state) => {
                state.status = StatusCode.ERROR;
            })
            .addCase(ViewQafetchFoodDetails.pending, (state) => {
                state.status = StatusCode.LOADING;
            })
            .addCase(ViewQafetchFoodDetails.fulfilled, (state, action) => {
                state.status = StatusCode.IDLE;
                state.datavalue = action.payload;
            })
            .addCase(ViewQafetchFoodDetails.rejected, (state) => {
                state.status = StatusCode.ERROR;
            })
            .addCase(uploadQaFoodDetails.pending, (state) => {
                state.status = StatusCode.LOADING;
            })
            .addCase(uploadQaFoodDetails.fulfilled, (state, action) => {
                state.status = StatusCode.IDLE;
                state.dataUpdateModel = action.payload;
            })
            .addCase(uploadQaFoodDetails.rejected, (state) => {
                state.status = StatusCode.ERROR;
            })
            ;
    }
});

export default QaSlice.reducer;


// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import StatusCode from '../../../Utils/StatusCode';
// const baseURL = 'http://192.168.1.24:7005/qa/list-of-tasks';

// const fetchData = async (url) => {
//     const response = await fetch(url);
//     const data = await response.json();
//     return data;
// };

// const createFetchThunk = (name, params) => {
//     const url = `${baseURL}?${params}`;
//     return createAsyncThunk(
//         name,
//         async () => {
//             const data = await fetchData(url);
//             return data;
//         }
//     );
// };

// export const getQaApproved = createFetchThunk('qa/fetchApproved', 'qa_id=INQA002&status=APPROVED');
// export const getQaReview = createFetchThunk('review/fetchreview', 'qa_id=INQA001&status=READY-TO-REVIEW');
// export const getQaReworkReview = createFetchThunk('reviewrework/fetchreviewreowrk', 'qa_id=INQA001&status=REWORK-READY-TO-REVIEW');
// export const getQaRework = createFetchThunk('reviewrework/fetchreviewreowrk', 'qa_id=INQA001&status=REWORK');

// const QaSlice = createSlice({
//     name: 'products',
//     initialState: {
//         data: [],
//         status: 'idle',
//         error: null
//     },
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(getQaApproved.pending, (state) => {
//                 state.status = StatusCode.LOADING;
//             })
//             .addCase(getQaApproved.fulfilled, (state, action) => {
//                 state.status = StatusCode.IDLE;
//                 state.data = action.payload;
//             })
//             .addCase(getQaApproved.rejected, (state) => {
//                 state.status = StatusCode.ERROR;
//             })
//             // Repeat the same for other thunks
//             .addCase(getQaReview.pending, (state) => {
//                 state.status = StatusCode.LOADING;
//             })
//             .addCase(getQaReview.fulfilled, (state, action) => {
//                 state.status = StatusCode.IDLE;
//                 state.data = action.payload;
//             })
//             .addCase(getQaReview.rejected, (state) => {
//                 state.status = StatusCode.ERROR;
//             })
//             .addCase(getQaReworkReview.pending, (state) => {
//                 state.status = StatusCode.LOADING;
//             })
//             .addCase(getQaReworkReview.fulfilled, (state, action) => {
//                 state.status = StatusCode.IDLE;
//                 state.data = action.payload;
//             })
//             .addCase(getQaReworkReview.rejected, (state) => {
//                 state.status = StatusCode.ERROR;
//             });
//     }
// });

// export default QaSlice.reducer;

