import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../Service/Context/AuthProvider';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewUsdIfct, editUsdaIfct, updateIngredients } from '../../../Service/ApiServices';
import { toast } from 'react-toastify';

function UsdaMasterTable() {
  const { userData } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dummyData, setDummyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState("USDA");
  const [GroupSelect, setGroupSelect] = useState("");
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    if (userData) {
      setLoading(true);
      dispatch(ViewUsdIfct({ data_origin: selectedOption, token: userData?.token, navigate }))
        .unwrap()
        .then((result) => {
         
          setDummyData(result);
          setGroupSelect(result[0]?.value);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        })
    }
  }, [dispatch, userData, selectedOption, navigate]);

  useEffect(() => {
    if (GroupSelect) {
      setLoading(true);
      const dataValue = {
        "data_origin": selectedOption,
        "group": GroupSelect
      }
      dispatch(editUsdaIfct({ data: dataValue, token: userData?.token, navigate }))
        .unwrap()
        .then((result) => {
        
          if(result?.message === 'Ingredients retrieved successfully')
          {
            setData(result?.data);
          }
        
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [GroupSelect]);

  const handleToggleChange = async (item) => {
    const updatedAction = item?.action === 1 ? 0 : 1;

    const updatedData = {
      igdt_id: item?.igdt_id,
      action: updatedAction,
      common_name: updatedAction === 0 ? "" : item?.common_name || null,
      updated_by: userData?.userId,
    };

    setData((prevData) =>
      prevData.map((dataItem) =>
        dataItem?.igdt_id === item?.igdt_id ? { ...dataItem, action: updatedAction, common_name: updatedData.common_name } : dataItem
      )
    );

    try {
      await handlePutRequest(updatedData);
    } catch (error) {
      console.error('Failed to update data:', error);
      setDummyData((prevData) =>
        prevData.map((dataItem) =>
          dataItem?.igdt_id === item?.igdt_id ? { ...dataItem, action: item?.action, common_name: item?.common_name } : dataItem
        )
      );
    }
  };

  const handlePutRequest = (updatedData) => {
    return dispatch(updateIngredients({
      token: userData?.token,
      navigate,
      updatedData,
    }))
      .unwrap()
      .then((response) => {
       
        if(response.status === "success"){
          toast.success("Data updated successfully.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        else{
          console.error(response)
        }
       
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  const handleCommonNameChange = (igdt_id, newCommonName) => {
    setData((prevData) =>
      prevData.map(item => item?.igdt_id === igdt_id ? { ...item, common_name: newCommonName } : item)
    );
  };

  const getFilteredData = () => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return data.filter((item) => {
      const itemName = item?.name?.toLowerCase() || "";
      const itemCommonName = item?.common_name?.toLowerCase() || "";

      return (
        itemName.includes(lowerCaseQuery) ||
        itemCommonName.includes(lowerCaseQuery) &&
        (selectedOption ? item?.status === selectedOption : true)
      );
    });
  };

  const filteredData = getFilteredData();
  const totalItems = filteredData?.length;
  const totalPages = Math?.ceil(totalItems / itemsPerPage);
  const currentItems = filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (startPage > 1) {
      pageNumbers.unshift('...');
      pageNumbers.unshift(1);
    }

    if (endPage < totalPages) {
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    }

    return pageNumbers?.map((number) => (
      <button
        key={number}
        onClick={() => setCurrentPage(number)}
        className={currentPage === number ? "active" : ""}
        disabled={number === '...'}
      >
        {number}
      </button>
    ));
  };

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div className='fw-bold' style={{ fontSize: "20px" }}>Master Ingredients</div>
        <div className="d-flex justify-content-end align-items-center">
          <span className="fw-bold" onClick={() => navigate("/vfs/admin-dashboard")} style={{ cursor: "pointer" }}>
            Dashboard
          </span>
          <span className="d-flex align-items-center">
            <span className="material-symbols-outlined m-0 size-right-icon" style={{ fontSize: '18px' }}>
              arrow_forward_ios
            </span>
          </span>
          <span>Ingredients</span>
        </div>
      </div>

      <div className='d-flex mt-2'>
        <div className='w-25 mt-1 me-2'>
          <input
            type='text'
            className='form-control'
            placeholder='Search by name or common name...'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className=' mt-1 me-1'>
          <select
            className='form-select'
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <option value="USDA">USDA</option>
            <option value="IFCT">IFCT</option>
          </select>
        </div>

        <div className=' mt-1'>
          <select
            className='form-select'
            value={GroupSelect}
            onChange={(e) => setGroupSelect(e.target.value)}
          >
            <option value="">Select a group</option>
            {dummyData?.map(option => (
              <option key={option?.display_name} value={option?.value}>
                {option?.display_name}
              </option>
            ))}
          </select>
        </div>

      </div>

      <div className='mt-3'>
        {loading ? (
          <h4 className="text-center mt-5">
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </h4>
        ) : (
          <table className="table table-rounded w-100">
            <thead>
              <tr>
                <th style={{ backgroundColor: "#F2F2F2", width: "10%" }}>Sr. No.</th>
                <th style={{ backgroundColor: "#F2F2F2", width: "10%" }}>ID</th>
                <th style={{ backgroundColor: "#F2F2F2", width: "50%" }}>Name</th>
                <th style={{ backgroundColor: "#F2F2F2", width: "30%" }}>Common Name</th>
                <th style={{ backgroundColor: "#F2F2F2", width: "20%" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">No data found</td>
                </tr>
              ) : (
                currentItems?.map((item, index) => (
                  <tr key={item?.igdt_id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{item?.igdt_id}</td>
                    <td>{item?.name}</td>
                    <td>
                      <input
                        className='form-control w-75'
                        placeholder='Enter common name'
                        value={item?.common_name || ''}
                        onChange={(e) => handleCommonNameChange(item.igdt_id, e.target.value)}
                      />
                    </td>
                    <td>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={item?.action === 1}
                          onChange={() => handleToggleChange(item)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>

      {currentItems?.length > 0 && (
        <div className="d-flex w-100 justify-content-between mt-3">
          <div className="tablemetrixdata">
            Showing data {(currentPage - 1) * itemsPerPage + 1} to {currentPage * itemsPerPage > totalItems ? totalItems : currentPage * itemsPerPage} of {totalItems}
          </div>
          <div className="pagination d-flex align-items-center">
            <button
              onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
              disabled={currentPage === 1}
              className="btn btn-outline-secondary me-2"
            >
              <span className="material-symbols-outlined">chevron_left</span>
            </button>
            {renderPageNumbers()}
            <button
              onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="btn btn-outline-secondary ms-2"
            >
              <span className="material-symbols-outlined">chevron_right</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UsdaMasterTable;
