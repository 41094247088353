import React from "react";
import Button from "../../Buttons/Button";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AdminAddUser, AdminpostEditUser } from "../../../Service/ApiServices";
import { useAuth } from "../../../Service/Context/AuthProvider";

function CancelModel({
  closeModalView,
  card,
  message,
  iconName,
  formData,
  condition,
 
}) {
  const navigate = useNavigate();
  const { userData,userupdateforceUpdate } = useAuth();
  const dispatch = useDispatch();
  const key = useParams();
  const { useradd, addusererror, statusadminadduser } = useSelector(
    (state) => state.admin
  );

  const decryptData = (encryptedData) => {
    try {
      const decodedData = atob(encryptedData);
      const sanitizedData = decodedData.replace(/[^\x20-\x7E]/g, "");
      const parts = sanitizedData.split("//");
      return parts[0];
    } catch (error) {
      console.error("Decryption failed", error);
      return null;
    }
  };

  const statekeyvalue = decryptData(key?.datavalue);

  const handleYes = async () => {
   
    try {
      if (condition === "Add") {
        const response = await dispatch(
          AdminAddUser({
            formData,
            token: userData.token,
            navigate
          })
        );
        if (response?.payload?.message === "User enrolled successfully") {
          toast.success("Enrollment Successful", {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: "toast-top-right",
          });
          
          navigate("/vfs/admin-dashboard", { state: { card } });
        } else {
          toast.error(response?.payload?.Status, {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: "toast-top-right",
          });
          closeModalView();
        }

      } else if (condition === 'Edit'){
        console.log("save,edit")
        const resultAction = await dispatch(
          AdminpostEditUser({
            formData: {
              user_fullname: formData.fullName,
              user_email: formData.email,
              role: formData.role,
              assigned_qa: formData.qa === "Select" ? "" : formData.qa,
              ph_code: formData.countrycodevalue,
              phone_number: formData.contactNumber,
              gender: formData.gender,
              dob: formData.dob,
              age: formData.age,
              pin_code: formData.zipCode,
              city: formData.city,
              state: formData.state,
              country: formData.country,
              address: formData.address,
              userId: statekeyvalue,
            },
            token: userData.token,
            navigate
          })
        );
        if(AdminpostEditUser.fulfilled.match(resultAction))
          {
            if (resultAction?.payload?.message === 'User updated successfully') {
              toast.success("User updated successfully", {
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                className: "toast-top-right",
              });
             
            navigate("/vfs/admin-dashboard", { state: { card } });
            userupdateforceUpdate()
            } 
          }
       
        // else {
        //   console.log(1111)
        //     closeModalView();
        //   throw new Error(resultAction.payload);
          
        // }
      }
      closeModalView();
    
    } catch (error) {
    //   closeModalView();
    //   toast.error("An error occurred during update", {
    //     autoClose: 2000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     className: "toast-top-right",
    //   });
    console.log(error)
    }
  };

  const handleCancel = () => {
    navigate("/vfs/admin-dashboard", { state: { card } });
  };

  return (
    <div className="cancel-model mx-1 my-3 align-items-center p-3">
      <div className="cancel-icon d-flex justify-content-center mb-3">
        <span className="material-symbols-outlined header-SubmitResearchermodel text-dark">
          {iconName}
        </span>
      </div>
      <div className="w-100 mb-3">
        <div className="text-center text-dark">{message}</div>
      </div>
      <div
        className="button submit-btn px-5 p-2 cursor-pointer"
        onClick={iconName === "cancel" ? handleCancel : handleYes}
      >
        <Button value="Yes" />
      </div>
      <div
        className="button cancel-btn px-5 p-2 admin-view mt-3"
        onClick={closeModalView}
      >
        <Button value="No" />
      </div>
    </div>
  );
}

export default CancelModel;
