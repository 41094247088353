
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadQaFoodDetails } from "../../../Service/ApiServices";
import { useAuth } from "../../../Service/Context/AuthProvider";
import Button from "../../Buttons/Button";


const CancelReviewQaModel = ({
    closeModalDraft,
  formData,
  selectedImages,
  statekey,
  status,
  card, blobstorage, audioname, audiomodelurl
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userData } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const uploadData = () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            dispatch(uploadQaFoodDetails({ formData, selectedImages, userData, status, statekey, token: userData.token, blobstorage, audioname, audiomodelurl ,navigate}))
                .unwrap()
                .then(() => {
                    toast.success("Data registered successfully", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        style: { marginTop: "60px" },
                    });
                    closeModalDraft();
                    navigate("/vfs/qa-dashboard", { state: { card } });
                })
                .catch((error) => {
                    toast.error("Error submitting data: " + error, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        style: { marginTop: "60px" },
                    });
                })
                .finally(() => {
                    setIsLoading(false); // Set loading to false when API call completes
                });

        }
        catch (error) {
            console.log(error);
        }
    };

    const closebutton = () => {
        closeModalDraft()
        navigate("/vfs/qa-dashboard", { state: { card } });
    }


    return (
        <div>
            <div className="text-center">
                <span className="mb-4 material-symbols-outlined mt-4 header-SubmitResearchermodel">
                    bookmark_added
                </span>
                <div>Do you want to save and Approve?</div>
                
                <div onClick={uploadData} className="submit-btn p-2 mx-4 mt-3">
                    <Button value={isLoading ? <span className="loader"></span> : "Yes"} disabled={isLoading} />
                </div>
                <div
                    onClick={closebutton}
                    className="cancel-btn p-2 mx-4 mt-3 mb-4"
                >
                    <Button value="No" />
                </div>
            </div>
        </div>
    );
};

export default CancelReviewQaModel;