import React, { useEffect, useRef, useState } from "react";
import Button from "../../Buttons/Button";

const SaveRecordQaModel = ({ closeModalViewsaveRecording, setaudioname, setaudiomodelurl, audioURL,savename }) => {
  const [name, setname] = useState('')
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setname(savename)
  }, [audioURL,savename]);
  const handlesave = () => {
    if (name !== '') {
      setaudioname(name)
      closeModalViewsaveRecording()
      setname('')
      setaudiomodelurl(audioURL)
    }

  }
  const handlecancel = () => {
    closeModalViewsaveRecording()
    setaudiomodelurl('')
  }
  return (
    <div>
      <div className="mx-3">
        <div className="my-3">Save Recording</div>
        <div>
          <input   autoComplete="off" type="text" className="border-0 border-bottom border-black w-100 " value={name} onChange={(e) => setname(e.target.value)} ref={inputRef} />
        </div>
        <div className="d-flex justify-content-between my-5">
          <div
            onClick={handlecancel}
            className="cancel-btn p-2 "
          >
            <Button value="Cancel" />
          </div>
          <div className="submit-btn p-2 px-3" onClick={handlesave}> <Button value="Save" /></div>
        </div>
      </div>
    </div>
  );
};

export default SaveRecordQaModel;