import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  ViewQafetchFoodDetails,
  fetchIngredientSuggestions,
} from "../../../../Service/ApiServices";
import { useAuth } from "../../../../Service/Context/AuthProvider";
import "../../../../Style/Researcher.scss";
import Button from "../../../../Utils/Buttons/Button";
import Model from "../../../../Utils/Model/Model";
import CancelReviewQaModel from "../../../../Utils/Model/QaModel/CancelReviewQaModel";
import DeleteRecordQaModel from "../../../../Utils/Model/QaModel/DeleteRecordQaModel";
import SaveRecordQaModel from "../../../../Utils/Model/QaModel/SaveRecordQaModel";
import SubmitQaModel from "../../../../Utils/Model/QaModel/SubmitQaModel";
import SubmitReeworkQaModel from "../../../../Utils/Model/QaModel/SubmitReeworkQaModel";
import EditNameRecordingModel from "../../../../Utils/Model/ResearcherModel/EditNameRecordingModel";
import {
  categoryOptions,
  countryOptions,
  foodGroupOptions,
  nutrientRichOptions,
  portionOptions,
  processedOptions,
  quantityOptions,
  quantityvalueOptions,
  therapeuticOptions,
  triglycerideOptions,
  unitOptions,
} from "../../../../Utils/Options/Options";
import ViewParameter from "../../../ViewParameter/ViewParameter";
const QaReviewRecipe = () => {
  const key = useParams();
  const { userData } = useAuth();
  const decryptData = (encryptedData) => {
    try {
      const base64 = encryptedData.datavalue
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      const decodedData = atob(base64);
      const sanitizedData = decodedData.replace(/[^\x20-\x7E]/g, "");
      const decryptedData = JSON.parse(sanitizedData);
      return decryptedData;
    } catch (error) {
      return null;
    }
  };
  const statekey = decryptData(key);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showsuggestions, setshowSuggestions] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [blobstorage, setblobstorage] = useState();
  const [audioname, setaudioname] = useState("");
  const [audiomodelurl, setaudiomodelurl] = useState("");
  const [formData, setFormData] = useState({
    comment: "",
    nameoffood: statekey ? statekey?.data?.recipes : "",
    spellingoffood: "",
    cuisine: statekey ? statekey?.data?.cuisine : "",
    country: "",
    portion: "",
    quantity: "",
    unit: "",
    Processed: "",
    categoryoffood: "",
    Foodgroup: "",
    Nutrientrich: "",
    Therapeutic: "",
    triglyceride: "",
    Hindi: "",
    Bengali: "",
    Gujarati: "",
    Marathi: "",
    Tamil: "",
    Telugu: "",
    Kannada: "",
    Malayalam: "",
    Punjabi: "",
    Odia: "",
    Urdu: "",
    referenceFoodIngredients: "",
    glycaemicIndex: "",
    referenceGI: "",
    ingredients: [
      {
        igdt_id: "",
        name_of_ingredient: "",
        quantity: "1",
        portion: "",
        amount: "",
      },
    ],
  });
  const [errorMessages, setErrorMessages] = useState({
    nameoffood: "",
    spellingoffood: "",
    cuisine: "",
    country: "",
    portion: "",
    quantity: "",
    unit: "",
    Processed: "",
    categoryoffood: "",
    Foodgroup: "",
    Nutrientrich: "",
    Therapeutic: "",
    triglyceride: "",
    referenceFoodIngredients: "",
    glycaemicIndex: "",
    referenceGI: "",
    ingredients: [
      { name_of_ingredient: "", quantity: "", portion: "", amount: "" },
    ],
    Hindi: "",
    Bengali: "",
    Gujarati: "",
    Marathi: "",
    Tamil: "",
    Telugu: "",
    Kannada: "",
    Malayalam: "",
    Punjabi: "",
    Odia: "",
    Urdu: "",
    image: "",
    audio: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { datavalue } = useSelector((state) => state.qaSlice);
  useEffect(() => {
    if (userData) {
      dispatch(
        ViewQafetchFoodDetails({
          userid: userData?.userId,
          rfnid: statekey?.data?.rfn_id,
          statusvalue: "READY-TO-REVIEW",
          token: userData.token,
          navigate
        })
      );
    }
  }, [dispatch, userData]);
  useEffect(() => {
    async function processIngredientName(query, index) {
      try {
        const resultAction = await dispatch(
          fetchIngredientSuggestions({ query, token: userData.token })
        );
        if (fetchIngredientSuggestions.fulfilled.match(resultAction)) {
          setSelectedSuggestions((prevData) => {
            const newData = resultAction.payload.data[0];
            const updatedData = [...prevData];
            updatedData[index] = newData;
            return updatedData;
          });
        } else {
          console.error("Failed to fetch ingredient suggestions for", query);
        }
      } catch (error) {
        console.error("Error processing ingredient name:", error);
      }
    }
    async function handleIngredients() {
      if (datavalue) {
        setaudioname(datavalue?.upload?.audio_file_name);
        setSelectedImages(datavalue?.upload?.image || []);
        setaudiomodelurl(datavalue?.upload?.audio || "");
        const ingredients =
          datavalue?.ingredients_details?.added_ingredients.map(
            async (ingredient, index) => {
              if (ingredient.name_of_ingredient) {
                await processIngredientName(
                  ingredient.name_of_ingredient,
                  index
                );
              }

              return {
                igdt_id: ingredient.igdt_id || "",
                name_of_ingredient: ingredient.name_of_ingredient || "",
                quantity: ingredient.quantity || "",
                portion: ingredient.portion || "",
                amount: ingredient.amount || "",
              };
            }
          ) || [
            {
              igdt_id: "",
              name_of_ingredient: "",
              quantity: "",
              portion: "",
              amount: "",
            },
          ];
        const resolvedIngredients = await Promise.all(ingredients);
        setFormData({
          comment: datavalue?.comments || "",
          nameoffood: datavalue?.food_details?.name_of_food || "",
          spellingoffood: datavalue?.food_details?.different_spellings || "",
          cuisine:
            datavalue?.food_details?.cuisine || statekey
              ? statekey?.data?.cuisine
              : "",
          country: datavalue?.food_details?.country || "",
          portion: datavalue?.food_details?.portion || "",
          quantity: datavalue?.food_details?.quantity || "",
          unit: datavalue?.food_details?.unit || "",
          Processed: datavalue?.food_details?.processed_food_level || "",
          categoryoffood: datavalue?.food_details?.category_of_food || "",
          Foodgroup: datavalue?.food_details?.food_group || "",
          Nutrientrich: datavalue?.food_details?.nutrient_rich || "",
          Therapeutic: datavalue?.food_details?.therapepeutic_use_of_food || "",
          triglyceride: datavalue?.food_details?.type_of_triglyceride || "",
          Hindi: datavalue?.languages?.hindi || "",
          Bengali: datavalue?.languages?.bengali || "",
          Gujarati: datavalue?.languages?.gujarati || "",
          Marathi: datavalue?.languages?.marathi || "",
          Tamil: datavalue?.languages?.tamil || "",
          Telugu: datavalue?.languages?.telugu || "",
          Kannada: datavalue?.languages?.kannada || "",
          Malayalam: datavalue?.languages?.malayalam || "",
          Punjabi: datavalue?.languages?.punjabi || "",
          Odia: datavalue?.languages?.odia || "",
          Urdu: datavalue?.languages?.urdu || "",
          referenceFoodIngredients:
            datavalue?.ingredients_details?.references || "",
          glycaemicIndex: datavalue?.ingredients_details?.gi.value || "",
          referenceGI:
            datavalue?.ingredients_details?.gi.reference_for_gi || "",
          ingredients: resolvedIngredients,
        });
      }
    }

    handleIngredients();
  }, [datavalue, dispatch]);

  const validateForm = () => {
    const errors = {};
    const urlPattern = /http?s?:\/\/.+$/;
    const isValid = (pattern, value) => pattern.test(value);
    if (!formData.nameoffood) {
      errors.nameoffood = "Please enter the name of the food.";
    }
    if (!formData.spellingoffood) {
      errors.spellingoffood = "Please enter the spelling of the food.";
    }
    if (!formData.cuisine) {
      errors.cuisine = "Please enter the cuisine.";
    }
    if (!formData.country) {
      errors.country = "Please enter the country.";
    }
    if (!formData.portion) {
      errors.portion = "Please enter the portion.";
    }
    if (!formData.quantity) {
      errors.quantity = "Please enter the quantity.";
    }
    if (!formData.unit) {
      errors.unit = "Please enter the unit.";
    }
    if (!formData.Processed) {
      errors.Processed = "Please enter the Processed food level.";
    }
    if (!formData.categoryoffood) {
      errors.categoryoffood = "Please enter the Category of food.";
    }
    if (!formData.Foodgroup) {
      errors.Foodgroup = "Please enter the Food Group.";
    }
    if (!formData.Nutrientrich) {
      errors.Nutrientrich = "Please enter the Nutrient rich.";
    }
    if (!formData.Therapeutic) {
      errors.Therapeutic = "Please enter the Therapeutic Use of Food.";
    }
    if (!formData.triglyceride) {
      errors.triglyceride = "Please enter the Type of Triglyceride.";
    }
    if (!formData.referenceFoodIngredients) {
      errors.referenceFoodIngredients =
        "Please enter the reference for food ingredients.";
    } else if (
      !(
        formData.referenceFoodIngredients.startsWith("http://") ||
        formData.referenceFoodIngredients.startsWith("https://")
      )
    ) {
      errors.referenceFoodIngredients =
        "URL must start with http:// or https://";
    } else if (!isValid(urlPattern, formData.referenceFoodIngredients)) {
      errors.referenceFoodIngredients = "Invalid URL.";
    }
    if (!formData.glycaemicIndex) {
      errors.glycaemicIndex = "Please enter the glycaemic index.";
    }
    if (!formData.referenceGI) {
      errors.referenceGI = "Please enter the reference for GI.";
    } else if (
      !(
        formData.referenceGI.startsWith("http://") ||
        formData.referenceGI.startsWith("https://")
      )
    ) {
      errors.referenceGI = "URL must start with http:// or https://";
    } else if (!isValid(urlPattern, formData.referenceGI)) {
      errors.referenceGI = "Invalid URL.";
    }

    formData.ingredients.forEach((ingredient, index) => {
      if (!ingredient.name_of_ingredient) {
        errors[`ingredient-name_of_ingredient-${index}`] =
          "Please enter the ingredient.";
      }
      if (!ingredient.quantity) {
        errors[`ingredient-quantity-${index}`] = "Please enter the quantity.";
      }
      if (!ingredient.portion) {
        errors[`ingredient-portion-${index}`] = "Please enter the portion.";
      }
    });

    if (!formData.Hindi) {
      errors.Hindi = "Please enter the Hindi translation.";
    }
    if (!formData.Bengali) {
      errors.Bengali = "Please enter the Bengali translation.";
    }
    if (!formData.Gujarati) {
      errors.Gujarati = "Please enter the Gujarati translation.";
    }
    if (!formData.Marathi) {
      errors.Marathi = "Please enter the Marathi translation.";
    }
    if (!formData.Tamil) {
      errors.Tamil = "Please enter the Tamil translation.";
    }
    if (!formData.Telugu) {
      errors.Telugu = "Please enter the Telugu translation.";
    }
    if (!formData.Kannada) {
      errors.Kannada = "Please enter the Kannada translation.";
    }
    if (!formData.Malayalam) {
      errors.Malayalam = "Please enter the Malayalam translation.";
    }
    if (!formData.Punjabi) {
      errors.Punjabi = "Please enter the Punjabi translation.";
    }
    if (!formData.Odia) {
      errors.Odia = "Please enter the Odia translation.";
    }
    if (!formData.Urdu) {
      errors.Urdu = "Please enter the Urdu translation.";
    }
    if (selectedImages.length < 3) {
      setErrorMessage("Please upload at least three images.");
    }
    if (!audiomodelurl) {
      errors.audio = "Please record an audio.";
    }

    setErrorMessages(errors);
    return errors;
  };
  const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]/g;
  const handleChange = (e) => {
    const { name, value } = e.target;
    const alphaNumericPattern = /^[a-zA-Z0-9, ]*$/;
    const numericPattern = /^[0-9]*$/;
    const isValid = (pattern, value) => pattern.test(value);
    let errorMessage = "";

    // Check for emojis and remove them
    const sanitizedValue = value.replace(emojiRegex, "");

    if (
      (name === "spellingoffood" || name === "name_of_ingredient") &&
      !isValid(alphaNumericPattern, sanitizedValue)
    ) {
      errorMessage = "This field accepts only alphanumeric characters.";
    } else if (
      (name === "quantity" || name === "glycaemicIndex") &&
      !isValid(numericPattern, sanitizedValue)
    ) {
      errorMessage = "This field accepts only numeric characters.";
    }

    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [name]: errorMessage,
    }));

    if (!errorMessage) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: sanitizedValue,
      }));
    }
  };

  const handleKeyDown = (event) => {
    if (event.which === 32 && event.ctrlKey && event.metaKey) {
      event.preventDefault();
    }
  };

  const startRecording = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Your browser does not support audio recording.");
      return;
    }
    setAudioURL("");
    audioChunksRef.current = [];
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/wav",
          });
          setblobstorage(audioBlob);
          const audioURL = URL.createObjectURL(audioBlob);
          setAudioURL(audioURL);
        };
        mediaRecorderRef.current.start();
        setIsRecording(true);
      })
      .catch((error) => {
        console.log("Could not start recording: " + error.message);
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      setIsRecording(false);
    }
    openModalViewsaveRecording();
  };

  const deleteAudio = () => {
    openModalViewdeleteRecording();
    setAudioURL("");
  };
  const [modalViewSubmit, setModalViewSubmit] = useState(false);
  const openModalViewSubmit = () => {
    const errors = validateForm();
    setErrorMessages(errors);
    if (Object.keys(errors).length !== 0 || selectedImages.length < 3) {
      return;
    }
    setModalViewSubmit(true);
  };
  const closeModalViewSubmit = () => {
    setModalViewSubmit(false);
  };
  const [modalViewParameters, setModalViewParameters] = useState(false);
  const openModalViewParameters = () => {
    setModalViewParameters(true);
  };
  const closeModalViewParameters = () => {
    setModalViewParameters(false);
  };

  const [modalReworkDraft, setModalReworkDraft] = useState(false);
  const closeModalReworkSubmit = () => {
    setModalReworkDraft(false);
  };
  const openModalReworkSubmit = () => {
    setModalReworkDraft(true);
  };

  const [modalCancelViewDraft, setCancelModalViewDraft] = useState(false);
  const openCancelModalViewDraft = () => {
    setCancelModalViewDraft(true);
  };
  const closeCancelModalViewDraft = () => {
    setCancelModalViewDraft(false);
  };
  const [modalViewsaveRecording, setModalViewsaveRecording] = useState(false);
  const openModalViewsaveRecording = () => {
    setModalViewsaveRecording(true);
  };
  const closeModalViewsaveRecording = () => {
    setModalViewsaveRecording(false);
  };
  const [modalViewEditNameRecording, setModalViewEditNameRecording] =
    useState(false);
  const openModalViewEditNameRecording = () => {
    setModalViewEditNameRecording(true);
  };
  const closeModalViewEditNameRecording = () => {
    setModalViewEditNameRecording(false);
  };
  const [modalViewdeleteRecording, setModalViewdeleteRecording] =
    useState(false);
  const openModalViewdeleteRecording = () => {
    setModalViewdeleteRecording(true);
  };
  const closeModalViewdeleteRecording = () => {
    setModalViewdeleteRecording(false);
  };

  const fetchIngredientSuggestionsdata = async (query, token, index) => {
    try {
      const resultAction = await dispatch(
        fetchIngredientSuggestions({ query, token })
      );
      if (fetchIngredientSuggestions.fulfilled.match(resultAction)) {
        return resultAction.payload;
      } else {
        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          [`ingredient-name_of_ingredient-${index}`]:
            "No ingredient suggestions",
        }));
        return [];
      }
    } catch (error) {
      setErrorMessages((prevErrorMessages) => ({
        ...prevErrorMessages,
        [`ingredient-name_of_ingredient-${index}`]: "No ingredient suggestions",
      }));
      return [];
    }
  };
  const calculateAmount = (quantity, portion, index) => {
    let data;
    if (
      Array.isArray(selectedSuggestions) &&
      selectedSuggestions[index] &&
      Array.isArray(selectedSuggestions[index].portion_amount)
    ) {
      data = selectedSuggestions[index].portion_amount.find(
        (item) => item.portion === portion
      )?.amount;
    }
    if (data !== undefined) {
      return (quantity * data).toFixed(2);
    }
    return 0;
  };

  const handleInputChange = async (index, e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(emojiRegex, "");
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [`ingredient-${name}-${index}`]: "",
    }));

    const newIngredients = [...formData.ingredients];
    newIngredients[index][name] = sanitizedValue;

    if (name === "quantity" || name === "portion") {
      const quantity = newIngredients[index].quantity || 0;
      const portion = newIngredients[index].portion || 0;
      newIngredients[index].amount = calculateAmount(quantity, portion, index);
    }

    if (name === "name_of_ingredient") {
      handleInputSearcherChange(index, sanitizedValue);
    }

    if (!errorMessage) {
      setFormData((formData) => ({ ...formData, ingredients: newIngredients }));
    }
  };

  const handleInputSearcherChange = debounce(async (index, value) => {
    if (value.length > 2) {
      setshowSuggestions(true);

      try {
        const fetchedSuggestions = await fetchIngredientSuggestionsdata(
          value,
          userData.token,
          index
        );
        setSuggestions(fetchedSuggestions.data);
      } catch (error) {
        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          [`ingredient-name_of_ingredient-${index}`]:
            "Error fetching suggestions",
        }));
      }
    }
  }, 2000);

  const bookhandlefunction = (suggestion, index) => {
    const newIngredients = [...formData.ingredients];
    newIngredients[index].igdt_id = suggestion.igdt_id;
    newIngredients[index].name_of_ingredient = suggestion.name;
    const newSelectedSuggestions = [...selectedSuggestions];
    newSelectedSuggestions[index] = suggestion;
    setSelectedSuggestions(newSelectedSuggestions);
    setFormData((formData) => ({ ...formData, ingredients: newIngredients }));
    setshowSuggestions(false);
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      setErrorMessage("Please select at least one image.");
      return;
    }

    const selectedImagesArray = Array.from(files);

    const unsupportedFiles = selectedImagesArray.filter(
      (file) =>
        !["image/jpeg", "image/png"].includes(file.type) || file.size > 52428800
    );

    if (unsupportedFiles.length > 0) {
      setErrorMessage("Please select JPG or PNG files under 50MB.");
      return;
    }

    setSelectedImages((prevSelectedImages) => {
      return [...prevSelectedImages, ...selectedImagesArray];
    });
    setErrorMessage("");
  };
  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };
  const handleFirstRemoveRow = () => {
    const newIngredients = formData.ingredients.filter((_, i) => i !== 0);
    setFormData((formData) => ({ ...formData, ingredients: newIngredients }));

    setSelectedSuggestions((prevData) => {
      const newData = [...prevData];
      newData.splice(0, 1);
      return newData;
    });
  };

  const handleAddRow = () => {
    const index = 0;
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [`ingredient-name_of_ingredient-${index}`]: "",
      [`ingredient-portion-${index}`]: "",
      [`ingredient-quantity-${index}`]: "",
    }));
    setSelectedSuggestions((prevData) => {
      const newData = [...prevData];
      newData.unshift({ igdt_id: "", name: "", portion_amount: "" });
      return newData;
    });
    setFormData((formData) => ({
      ...formData,
      ingredients: [
        {
          igdt_id: "",
          name_of_ingredient: "",
          quantity: "1",
          portion: "",
          amount: "",
        },
        ...formData.ingredients,
      ],
    }));
  };

  const handleRemoveRow = (index) => {
    const newIngredients = formData.ingredients.filter((_, i) => i !== index);
    setFormData((formData) => ({ ...formData, ingredients: newIngredients }));

    setSelectedSuggestions((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };


  const handleselectChangeisMulti = (data, fieldName) => {
    // Clear the error message for the specific field
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [fieldName]: '',
    }));

    // Update the form data
    setFormData((formData) => ({
      ...formData,
      [fieldName]: data ? data.map(option => option.value) : [],
    }));
  };

  const handleselectChange = (data, fieldName) => {
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [fieldName]: "",
    }));
    setFormData((formData) => ({ ...formData, [fieldName]: data.value }));
  };

  return (
    <div className="RecipesDetail recipesdetailsadddetails mx-4">
      <div className="text-end">
        <h6
          className="cursor-pointer view-parameters "
          onClick={openModalViewParameters}
        >
          View Parameter
        </h6>
      </div>
      <div className=" RecipesDetail-main card w-100 p-3 my-3 ">
        <h4 className="food-details-heading  mt-3 mb-4 fw-bolder">
          Food Details
        </h4>
        <div className="food-details-list-1st row">
          <div className="col-md-3 ">
            <div className="">
              <label className="mb-2  text-nowrap">Name of the Food</label>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="nameoffood"
                  value={formData.nameoffood}
                  className="form-control "
                  placeholder="Enter"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">
                Different Spellings(Commonly Used)
              </label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="spellingoffood"
                  value={formData.spellingoffood}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.spellingoffood && (
                  <div className="error-message">
                    {errorMessages.spellingoffood}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Cuisine</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  name="cuisine"
                  value={formData.cuisine}
                  className="form-control "
                  disabled
                />
                {errorMessages.cuisine && (
                  <div className="error-message">{errorMessages.cuisine}</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Country</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <Select
                  name="country"
                  value={countryOptions.find(
                    (option) => option.value === formData.country
                  )}
                  onChange={(selectedOption) =>
                    handleselectChange(selectedOption, "country")
                  }
                  options={countryOptions}
                  placeholder="Select"
                  classNamePrefix="custom-select-researcher"
                />
                {errorMessages.country && (
                  <div className=" error-message">{errorMessages.country}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="food-details-list-2st row mt-4">
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">
                Portion (Standard Values)
              </label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <Select
                  name="portion"
                  value={portionOptions.find(
                    (option) => option.value === formData.portion
                  )}
                  onChange={(selectedOption) =>
                    handleselectChange(selectedOption, "portion")
                  }
                  options={portionOptions}
                  placeholder="Select"
                  classNamePrefix="custom-select-researcher"
                />

                {errorMessages.portion && (
                  <div className="error-message">{errorMessages.portion}</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">
                Quantity (Standard Values)
              </label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <Select
                  name="quantity"
                  value={quantityOptions.find(
                    (option) => option.value === formData.quantity
                  )}
                  onChange={(selectedOption) =>
                    handleselectChange(selectedOption, "quantity")
                  }
                  options={quantityOptions}
                  placeholder="Select"
                  classNamePrefix="custom-select-researcher"
                />
                {errorMessages.quantity && (
                  <div className="error-message">{errorMessages.quantity}</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">
                Unit (Standard Values)
              </label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <Select
                  name="unit"
                  value={unitOptions.find(
                    (option) => option.value === formData.unit
                  )}
                  onChange={(selectedOption) =>
                    handleselectChange(selectedOption, "unit")
                  }
                  options={unitOptions}
                  placeholder="Select"
                  classNamePrefix="custom-select-researcher"
                />
                {errorMessages.unit && (
                  <div className="error-message">{errorMessages.unit}</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Processed Food Level</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <Select
                  name="Processed"
                  value={processedOptions.find(
                    (option) => option.value === formData.Processed
                  )}
                  onChange={(selectedOption) =>
                    handleselectChange(selectedOption, "Processed")
                  }
                  options={processedOptions}
                  placeholder="Select"
                  classNamePrefix="custom-select-researcher"
                />
                {errorMessages.Processed && (
                  <div className="error-message">{errorMessages.Processed}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="food-details-list-3st row mt-4">
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Category of Food</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <Select
                  name="categoryoffood"
                  value={categoryOptions.filter(option => formData.categoryoffood?.includes(option.value))}
                  onChange={(selectedOption) => handleselectChangeisMulti(selectedOption, "categoryoffood")}
                  options={categoryOptions}
                  placeholder="Select"
                  classNamePrefix="custom-select-researcher"
                  isMulti
                />
                {errorMessages.categoryoffood && (
                  <div className="error-message">
                    {errorMessages.categoryoffood}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Food Group</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <Select
                  name="Foodgroup"
                  value={foodGroupOptions.filter(option => formData.Foodgroup?.includes(option.value))}
                  onChange={(selectedOption) => handleselectChangeisMulti(selectedOption, "Foodgroup")}
                  options={foodGroupOptions}
                  placeholder="Select"
                  classNamePrefix="custom-select-researcher"
                  isMulti
                />
                {errorMessages.Foodgroup && (
                  <div className="error-message">{errorMessages.Foodgroup}</div>
                )}
              </div>
            </div>
          </div>{" "}
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Nutrient Rich</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <Select
                  name="Nutrientrich"
                  value={nutrientRichOptions.filter(option => formData.Nutrientrich?.includes(option.value))}
                  onChange={(selectedOption) => handleselectChangeisMulti(selectedOption, "Nutrientrich")}
                  options={nutrientRichOptions}
                  placeholder="Select"
                  classNamePrefix="custom-select-researcher"
                  isMulti
                />
                {errorMessages.Nutrientrich && (
                  <div className="error-message">
                    {errorMessages.Nutrientrich}
                  </div>
                )}
              </div>
            </div>
          </div>{" "}
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">
                Therapeutic Use of Food
              </label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <Select
                  name="Therapeutic"
                  value={therapeuticOptions.filter(option => formData.Therapeutic?.includes(option.value))}
                  onChange={(selectedOption) => handleselectChangeisMulti(selectedOption, "Therapeutic")}
                  options={therapeuticOptions}
                  placeholder="Select"
                  classNamePrefix="custom-select-researcher"
                  isMulti
                />
                {errorMessages.Therapeutic && (
                  <div className="error-message">
                    {errorMessages.Therapeutic}
                  </div>
                )}
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="food-details-list-4st row mt-4">
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Type of Triglyceride</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <Select
                  name="triglyceride"
                  value={triglycerideOptions.find(
                    (option) => option.value === formData.triglyceride
                  )}
                  onChange={(selectedOption) =>
                    handleselectChange(selectedOption, "triglyceride")
                  }
                  options={triglycerideOptions}
                  placeholder="Select"
                  classNamePrefix="custom-select-researcher"
                />
                {errorMessages.triglyceride && (
                  <div className="error-message">
                    {errorMessages.triglyceride}
                  </div>
                )}
              </div>
            </div>
          </div>{" "}
        </div>
        <h4 className="ingredients-details-heading  mt-5 mb-4 fw-bolder">
          Ingredients Details
        </h4>
        <div className="row w-100">
          <div className="ingredients-details col-md-6">
            <div className="scrollable-wrapper mt-4">
              {formData.ingredients.map((ingredient, index) => (
                <div className="row mb-5" key={index}>
                  <div className="col-md input-container-adduser  ">
                    <label className="mb-2  text-nowrap">
                      Name of Ingredient <small className="red-star">*</small>
                    </label>
                    <input
                      autoComplete="off"
                      type="text"
                      name="name_of_ingredient"
                      value={ingredient.name_of_ingredient}
                      onChange={(e) => handleInputChange(index, e)}
                      className="form-control "
                      onFocus={() => setFocusedIndex(index)}
                      placeholder="Enter"
                    />
                    {suggestions?.length > 0 &&
                      showsuggestions &&
                      focusedIndex === index && (
                        <div className="dropdown-name">
                          {suggestions.map((suggestion) => (
                            <div
                              className="mt-2 border-bottom"
                              key={suggestion.igdt_id}
                              onClick={() =>
                                bookhandlefunction(suggestion, index)
                              }
                            >
                              {suggestion.name}
                            </div>
                          ))}
                        </div>
                      )}
                    {errorMessages[
                      `ingredient-name_of_ingredient-${index}`
                    ] && (
                      <div className="error-message ms-3">
                        {
                          errorMessages[
                            `ingredient-name_of_ingredient-${index}`
                          ]
                        }
                      </div>
                    )}
                  </div>
                  <div className="col-md ">
                    <label className="mb-2 input-box-ingredient text-nowrap">
                      Quantity <small className="red-star">*</small>
                    </label>
                    <div className="input-container-adduser">
                      <Select
                        name="quantity"
                        value={
                          quantityvalueOptions.find((option) => option.value === ingredient.quantity) ||
                          { label: ingredient.quantity, value: ingredient.quantity }
                        }
                        onChange={(selectedOption) =>
                          handleInputChange(index, {
                            target: {
                              name: "quantity",
                              value: selectedOption.value,
                            },
                          })
                        }
                        options={quantityvalueOptions}
                        placeholder="Select"
                        classNamePrefix="custom-select-researcher"
                      />
                      {errorMessages[`ingredient-quantity-${index}`] && (
                        <div className="error-message">
                          {errorMessages[`ingredient-quantity-${index}`]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md">
                    <label className="mb-2 input-box-ingredient text-nowrap">
                      Portion <small className="red-star">*</small>
                    </label>
                    <div className="input-container-adduser">
                      <select
                        name="portion"
                        value={ingredient.portion}
                        onChange={(e) => handleInputChange(index, e)}
                        className="form-control"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {selectedSuggestions[index]?.portion_amount &&
                          selectedSuggestions[index].portion_amount.map(
                            (portion, i) => (
                              <option key={i} value={portion.portion}>
                                {portion.portion}
                              </option>
                            )
                          )}
                      </select>
                      {errorMessages[`ingredient-portion-${index}`] && (
                        <div className="error-message">
                          {errorMessages[`ingredient-portion-${index}`]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md">
                    <label className="mb-2 input-box-ingredient input-box-ingredient-data2 text-nowrap">
                      Amount (grams)
                    </label>
                    <input
                      autoComplete="off"
                      name="amount"
                      value={ingredient.amount}
                      onChange={(e) => handleInputChange(index, e)}
                      className="form-control"
                      disabled
                      placeholder="Amount"
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="col-md input-box-ingredient-data d-flex ">
                    {index === 0 && (
                      <>
                        {formData.ingredients.length > 1 && (
                          <span
                            className="material-symbols-outlined delete-button mt-3 me-2"
                            onClick={() => handleFirstRemoveRow()}
                          >
                            delete
                          </span>
                        )}
                        <span
                          className=" add-button  mt-3"
                          onClick={handleAddRow}
                        ></span>
                      </>
                    )}
                    {index > 0 && (
                      <span
                        className="material-symbols-outlined delete-button mt-3 "
                        onClick={() => handleRemoveRow(index)}
                      >
                        delete
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="right col-md-6 mt-4  ">
            <div>
              <label className="mb-2  text-nowrap">
                Reference for Food Ingredients{" "}
              </label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="url"
                  pattern="https?://.+"
                  name="referenceFoodIngredients"
                  value={formData.referenceFoodIngredients}
                  onChange={handleChange}
                  className="w-100 form-control"
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.referenceFoodIngredients && (
                  <div className="error-message">
                    {errorMessages.referenceFoodIngredients}
                  </div>
                )}
              </div>
            </div>
            <div className="Glycaemic Index mt-2 row">
              <div className="col-md mt-3">
                <label className="mb-2  text-nowrap">
                  Glycaemic Index <small className="red-star">*</small>
                </label>
                <div className="input-container-adduser">
                  <input
                    autoComplete="off"
                    type="text"
                    name="glycaemicIndex"
                    value={formData.glycaemicIndex}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter"
                    onKeyDown={handleKeyDown}
                  />
                  {errorMessages.glycaemicIndex && (
                    <div className="error-message">
                      {errorMessages.glycaemicIndex}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md mt-3">
                <label className="mb-2  text-nowrap">
                  Reference for Glycaemic Index{" "}
                  <small className="red-star">*</small>
                </label>
                <div className="input-container-adduser">
                  <input
                    autoComplete="off"
                    type="url"
                    pattern="https?://.+"
                    name="referenceGI"
                    value={formData.referenceGI}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter"
                    onKeyDown={handleKeyDown}
                  />
                  {errorMessages.referenceGI && (
                    <div className="error-message">
                      {errorMessages.referenceGI}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4 className="food-name-in-different-languages  mt-5 mb-4 fw-bolder">
        Food Name in Different Languages
        </h4>
        <div className="food-name-in-different-languages-list-2st row">

          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Hindi</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Hindi"
                  value={formData.Hindi}
                  onChange={handleChange}
                  className="form-control prevent-emoji"
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Hindi && (
                  <div className="error-message">{errorMessages.Hindi}</div>
                )}
              </div>
            </div>
          </div>
          
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Bengali</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Bengali"
                  value={formData.Bengali}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Bengali && (
                  <div className="error-message">{errorMessages.Bengali}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Gujarati</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Gujarati"
                  value={formData.Gujarati}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Gujarati && (
                  <div className="error-message">{errorMessages.Gujarati}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Marathi</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Marathi"
                  value={formData.Marathi}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Marathi && (
                  <div className="error-message">{errorMessages.Marathi}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="food-name-in-different-languages-list-3st row mt-3">
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Tamil</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Tamil"
                  value={formData.Tamil}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Tamil && (
                  <div className="error-message">{errorMessages.Tamil}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Telugu</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Telugu"
                  value={formData.Telugu}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Telugu && (
                  <div className="error-message">{errorMessages.Telugu}</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Kannada</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Kannada"
                  value={formData.Kannada}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Kannada && (
                  <div className="error-message">{errorMessages.Kannada}</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Malayalam</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Malayalam"
                  value={formData.Malayalam}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Malayalam && (
                  <div className="error-message">{errorMessages.Malayalam}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="food-name-in-different-languages-list-4st row mt-3">
          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Punjabi</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Punjabi"
                  value={formData.Punjabi}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Punjabi && (
                  <div className="error-message">{errorMessages.Punjabi}</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Odia</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Odia"
                  value={formData.Odia}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Odia && (
                  <div className="error-message">{errorMessages.Odia}</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="">
              <label className="mb-2  text-nowrap">Urdu</label>
              <small className="red-star">*</small>
              <div className="input-container-adduser">
                <input
                  autoComplete="off"
                  type="text"
                  name="Urdu"
                  value={formData.Urdu}
                  onChange={handleChange}
                  className="form-control "
                  placeholder="Enter"
                  onKeyDown={handleKeyDown}
                />
                {errorMessages.Urdu && (
                  <div className="error-message">{errorMessages.Urdu}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex food-audio row">
          <div className="food-image col-md-6  input-container-adduser">
            <h4 className="add-food-images fw-bolder  mt-5 mb-4 ">
              Food Images
            </h4>
            <div className="add-food-images-input ">
              <button
                className="btn  my-3 upload-button-image"
                onClick={() => {
                  const input = document.createElement("input");
                  input.type = "file";
                  input.accept = ".jpg, .png";
                  input.multiple = true;
                  input.style.display = "none";
                  input.addEventListener("change", (event) =>
                    handleImageUpload(event)
                  );
                  document.body.appendChild(input);
                  input.click();
                }}
              >
                Upload Image
              </button>
              <div className="text-center">
                <small className="text-muted ">
                  Supported ormats: JPG, PNG. Max size: 50MB
                </small>
              </div>
            </div>
            {errorMessage && (
              <div className="error-message ms-2">{errorMessage}</div>
            )}
            <div className="mt-3 grid-container">
              {selectedImages.map((image, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={
                      typeof image === "string"
                        ? image
                        : URL.createObjectURL(image)
                    }
                    alt={`Image ${index}`}
                    style={{ width: "100%", mINheight: "100%" }}
                  />

                  <span
                    className="material-symbols-outlined close-button"
                    onClick={() => handleRemoveImage(index)}
                  >
                    close
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="audio col-md-6">
            <h4 className="add-food-images mt-5 mb-4 fw-bolder">
              Recorded Food Name
            </h4>
            <div></div>
            <div
              className="audio-recording row"
              style={{ alignItems: "center" }}
            >
              {!audiomodelurl && (
                <div className="input-container-adduser">
                  {!isRecording ? (
                    <>
                      <span
                        className="material-symbols-outlined stop-icon "
                        onClick={startRecording}
                      >
                        mic
                      </span>
                      {errorMessages.audio && (
                        <div className="error-message ms-3">
                          {errorMessages.audio}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="d-flex align-items-center">
                      <span
                        className="material-symbols-outlined stop-icon"
                        onClick={stopRecording}
                      >
                        stop
                      </span>

                      {isRecording && mediaRecorderRef.current && (
                        <LiveAudioVisualizer
                          mediaRecorder={mediaRecorderRef.current}
                          width={200}
                          height={20}
                          barColor={"black"}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
              {audiomodelurl && (
                <div
                  className="w-100"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="audio-relative mt-3">
                    <div className="audio-absolute fw-bold d-flex justify-content-center align-items-center">
                      <span className="text-nowrap">{audioname}</span>
                      <span
                        className="material-symbols-outlined ms-2 cursor-pointer edit-icon fs-5"
                        onClick={openModalViewEditNameRecording}
                      >
                        edit
                      </span>
                    </div>
                    <AudioPlayer
                      src={audiomodelurl}
                      showJumpControls={false}
                      layout={"stacked-reverse"}
                      showDownloadProgress={false}
                      hasDefaultKeyBindings={false}
                      autoPlayAfterSrcChange={false}
                      showFilledVolume={false}
                    />
                  </div>
                  <span
                    className="material-symbols-outlined delete-icon delete-background"
                    onClick={deleteAudio}
                  >
                    delete
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <textarea
            className="form-control mb-4"
            value={formData.comment}
            onChange={handleChange}
            placeholder="Add comments for maximizing efficiency while researchers rework"
            name="comment"
          ></textarea>
        </div>
        <div className="d-flex justify-content-between">
          <div
            onClick={openCancelModalViewDraft}
            className="cancel-btn px-3 py-2 me-2 text-center"
          >
            <Button value="Cancel" />
          </div>
          <div className="d-flex">
            <div
              className="submit-btn px-3 py-2 text-center"
              onClick={
                formData.comment ? openModalReworkSubmit : openModalViewSubmit
              }
            >
              {formData.comment ? (
                <Button value="Rework" />
              ) : (
                <Button value="Approve" />
              )}
            </div>
          </div>
        </div>
      </div>
      {
        <Model
          data="modal-sm"
          showModal={modalViewSubmit}
          closeModal={closeModalViewSubmit}
          content={
            <SubmitQaModel
              closeModalsubmit={closeModalViewSubmit}
              formData={formData}
              selectedImages={selectedImages}
              statekey={statekey}
              status="APPROVED"
              card={1}
              blobstorage={blobstorage}
              audioname={audioname}
              audiomodelurl={audiomodelurl}
            />
          }
        />
      }
      {
        <Model
          data="modal-sm"
          showModal={modalCancelViewDraft}
          closeModal={closeCancelModalViewDraft}
          content={
            <CancelReviewQaModel
              closeModalDraft={closeCancelModalViewDraft}
              formData={formData}
              selectedImages={selectedImages}
              statekey={statekey}
              status="APPROVED"
              card={1}
              blobstorage={blobstorage}
              audioname={audioname}
              audiomodelurl={audiomodelurl}
            />
          }
        />
      }
      {
        <Model
          data="modal-sm"
          showModal={modalViewsaveRecording}
          closeModal={closeModalViewsaveRecording}
          content={
            <SaveRecordQaModel
              closeModalViewsaveRecording={closeModalViewsaveRecording}
              setaudioname={setaudioname}
              audioURL={audioURL}
              setaudiomodelurl={setaudiomodelurl}
              savename={formData.nameoffood}
            />
          }
        />
      }
      {
        <Model
          data="modal-sm"
          showModal={modalViewdeleteRecording}
          closeModal={closeModalViewdeleteRecording}
          content={
            <DeleteRecordQaModel
              closeModalViewdeleteRecording={closeModalViewdeleteRecording}
              setaudiomodelurl={setaudiomodelurl}
              setaudioname={setaudioname}
              setblobstorage={setblobstorage}
            />
          }
        />
      }
      {
        <Model
          data="modal-sm"
          showModal={modalViewEditNameRecording}
          closeModal={closeModalViewEditNameRecording}
          content={
            <EditNameRecordingModel
              closeModalViewEditNameRecording={closeModalViewEditNameRecording}
              setaudioname={setaudioname}
              audioname={audioname}
            />
          }
        />
      }
      {
        <Model
          data="modal-sm"
          showModal={modalReworkDraft}
          closeModal={closeModalReworkSubmit}
          content={
            <SubmitReeworkQaModel
              closeModalsubmit={closeModalReworkSubmit}
              formData={formData}
              selectedImages={selectedImages}
              statekey={statekey}
              status="REWORK"
              card={1}
              blobstorage={blobstorage}
              audioname={audioname}
              audiomodelurl={audiomodelurl}
            />
          }
        />
      }{" "}
      {
        <Model
          data="modal-xl"
          showModal={modalViewParameters}
          closeModal={closeModalViewParameters}
          content={
            <ViewParameter
              closeModal={closeModalViewParameters}
              datavalue={datavalue}
            />
          }
        />
      }
    </div>
  );
};

export default QaReviewRecipe;
