import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AdminExcelDownload,
  AdminExcelUpload,
  TaskAllocate,
} from "../../../Service/ApiServices";
import { useAuth } from "../../../Service/Context/AuthProvider";
import "../../../Style/Admin.scss";
import Button from "../../../Utils/Buttons/Button";
import StatusCode from "../../../Utils/StatusCode";
import { useNavigate } from "react-router-dom";

const AdminExcel = ({ fooduploadforceUpdate }) => {
  const { userData } = useAuth();
  const dispatch = useDispatch();
  const navigate =useNavigate()
  const { postedituser, statusupload } = useSelector((state) => state.admin);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (fileExtension === "xlsx") {
      setSelectedFile(file);
    } else {
      alert("Please upload a file with .xlsx extension");
      event.target.value = "";
    }
  };

  const handleDelete = () => {
    setSelectedFile(null);
    document.getElementById("fileInput").value = "";
  };

  // const downloadfoodtemplate = async () => {
  //   try {
  //     const action = await dispatch(AdminExcelDownload({token : userData.token,navigate}));

  //     if (AdminExcelDownload.fulfilled.match(action)) {
  //       const jsonresp = action.payload;
  //       const link = jsonresp?.sasUrl;

  //       if (link) {
  //         const a = document.createElement("a");
  //         a.href = link;
  //         a.download = ""; // Optional: set a default file name
  //         document.body.appendChild(a);
  //         a.click();
  //         document.body.removeChild(a);

  //         return { success: true, message: "Download initiated successfully" };
  //       } else {
  //         return { success: false, message: "Download link is not available" };
  //       }
  //     } else {
  //       return {
  //         success: false,
  //         message: action.error.message || "Failed to initiate download",
  //       };
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return { success: false, message: "Failed to initiate download" };
  //   }
  // };

  const downloadfoodtemplate = async () => {
    try {
     
      const link = process.env.REACT_APP_DOWNLOAD_TEMPLATE_URL;
  
      if (link) {
       
        const a = document.createElement("a");
        a.href = link;
        a.download = ""; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
  
        return { success: true, message: "Download initiated successfully" };
      } else {
        return { success: false, message: "Download link is not available" };
      }
    } catch (error) {
      console.error(error);
      return { success: false, message: "Failed to initiate download" };
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      toast.error("Please select a file", {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "toast-top-right",
      });
      return;
    }

    try {
      // Dispatch the upload thunk
      const uploadResultAction = await dispatch(
        AdminExcelUpload({
          file: selectedFile,
          userId: userData.userId,
          token: userData.token,
          navigate
        })
      );

      const uploadResponse = uploadResultAction.payload;
    
      if (uploadResponse.message === "No researchers to allocate") {
        setSelectedFile(null);
        document.getElementById("fileInput").value = ""; // Reset file input
        toast.info("No researchers to allocate", {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          className: "toast-top-right",
        });
      }
      if (uploadResponse?.status === "success") {
        // Dispatch the task allocation thunk
        const allocateResultAction = await dispatch(
          TaskAllocate({
            userId: userData.userId,
            token: userData.token,
            navigate
          })
        );
        

        const allocateResponse = allocateResultAction.payload;

        if (allocateResponse?.message === "Allocation completed successfully") {
          setSelectedFile(null);

          document.getElementById("fileInput").value = ""; // Reset file input
          toast.success("Tasks allocated successfully", {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: "toast-top-right",
          });
          fooduploadforceUpdate();
        } else if (allocateResponse?.message === "No tasks to allocate") {
          setSelectedFile(null);
          document.getElementById("fileInput").value = ""; // Reset file input
          toast.info("No tasks to allocate", {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: "toast-top-right",
          });
        }
        else {
          throw new Error("Unexpected allocation response");
        }
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      console.error(error)
    }
  };

  if (statusupload === StatusCode.LOADING) {
    return (
      <>
      <h4 className="text-center mt-5">
        <div class="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </h4>
      <div className="d-flex justify-content-center align-items-center">Please hold on while we upload the food recipes...</div>
      </>
    );
  }

  return (
    <div className="admin-excel px-2 card shadow px-4">
      <h5 className="my-4 fw-bold">Upload Food items here!</h5>

      <div className="mt-3 admin-excel-main">
        <input
          className="choose-file"
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          accept=".xlsx"
        />
        <button
          className="btn border-black"
          onClick={() => document.getElementById("fileInput").click()}
        >
          Upload a file
        </button>
        <p className="mt-2 supported-formats">Supported Formats: XLSX</p>
      </div>
      {selectedFile && (
        <div className="d-flex align-items-center mt-3">
          <small className="file-name">{selectedFile.name}</small>
          <span
            className="material-symbols-outlined cursor-pointer delete-background"
            onClick={handleDelete}
          >
            delete
          </span>
        </div>
      )}
      <div className="my-4 d-flex w-100 justify-content-between align-items-center">
        <div
          className="download-text cursor-pointer"
          onClick={downloadfoodtemplate}
        >
          Download Food Template
        </div>

        <div
          onClick={handleSubmit}
          className="submitbutton-cancel cursor-pointer p-2"
        >
          <div className="button cancel-btn p-2">
            <Button value="Submit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminExcel;
