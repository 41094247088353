import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUserInfodetails, userLogout } from "../../Service/ApiServices";
import { useAuth } from "../../Service/Context/AuthProvider";
import "../../Style/UserModel.scss";

function Profile() {
  const { userData, logoutremove,userupdatereducer } = useAuth();
const [userdeatils,setUserDetails]=useState()
  const [isDotCollapseOpen, setIsDotCollapseOpen] = useState(false);
  const profileCardRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileCardRef.current &&
        !profileCardRef.current.contains(event.target)
      ) {
        setIsDotCollapseOpen(false);
        document.body.classList.remove("background-blur");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

   useEffect(() => {
    if(userData){
      const fetchUserInfo = async () => {
        try {
          const response = await dispatch(
            fetchUserInfodetails({ email: userData?.email, token: userData?.token,navigate })
          ).unwrap();
         
          setUserDetails(response);
        } catch (error) {
          console.error('Failed to fetch user info:', error);
        }
      };
  
      fetchUserInfo();
    }
   
  }, [dispatch,userupdatereducer,userData]);

  const handleLogout = async () => {
    try {
      const resultAction = await dispatch(userLogout(userData.token));

      if (userLogout.fulfilled.match(resultAction)) {

        logoutremove()
        navigate("/login");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error occurred during logout:", error);
    }
  };

  const handleProfileClick = () => {
    setIsDotCollapseOpen(!isDotCollapseOpen);
    if (!isDotCollapseOpen) {
      document.body.classList.add("background-blur");
    } else {
      document.body.classList.remove("background-blur");
    }
  };

  function containsWhitespace(str) {
    return /\s/.test(str);
  }

  function getFirstLetters(str) {
    if (!str) return "";
    if (containsWhitespace(str)) {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("");
    }
    return str.charAt(0).toUpperCase();
  }

  const capitalizeWords = (str) => {
    if (!str) return ''; // Return empty string if input is falsy
    return str
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      .join(' '); // Join the words back together
  };

  return (
    <div className="profile-wrapper">
      <div className="profile d-flex align-items-center">
        <div className="user-name">
          <span className="fw-bold">
            {/* {userData?.loggedName?.charAt(0)?.toUpperCase() +
              userData?.loggedName?.slice(1)} */}
            {capitalizeWords(userdeatils?.user_fullname)}
          </span>
          <span className="d-flex justify-content-end user-role-name">
            {userData?.role === 'super_admin' ? "Super Admin" : userData?.role === 'qa' ? "QA" : userData?.role?.charAt(0)?.toUpperCase() + userData?.role?.slice(1)}
          </span>
        </div>

        <div
          onClick={handleProfileClick}
          className="profile-icon cursor-pointer ms-3"
        >
          {/* <span className="material-symbols-outlined fs-2">account_circle</span> */}
          <div className="dot"> {getFirstLetters(userdeatils?.user_fullname)}</div>
        </div>

        <div
          className={`collapse mt-5 ${isDotCollapseOpen ? "show" : ""}`}
          id="dotCollapse"
          ref={profileCardRef}
        >
          <div className="colpes-height card card-body">
            <center>
              <h2 className=" header-dot-background fs-6">
                {getFirstLetters(userdeatils?.user_fullname)}
              </h2>
              <div className="mt-2 fw-bold">
                {/* {userData?.loggedName?.charAt(0)?.toUpperCase() +
                  userData?.loggedName?.slice(1)} */}
                {capitalizeWords(userdeatils?.user_fullname)}
              </div>
              <span className="text-muted user-role-name">
                {userData?.role === 'super_admin'
                  ? "Super Admin"
                  : userData?.role === 'qa'
                    ? "QA"
                    : userData?.role?.charAt(0)?.toUpperCase() + userData?.role?.slice(1)}


              </span>
              <hr className="mt-4"></hr>
              {userData?.role === "researcher" ?
                <div className="text-center">
                 <div className="">
                 <div className=" fw-bold">QA </div> 
                 <div> {userData?.assigned_qa_name ? userData?.assigned_qa_name?.charAt(0)?.toUpperCase() + userData?.assigned_qa_name?.slice(1) : "-"}</div>
                 </div>
                  <hr className="mt-3"></hr>

                </div> : ""

              }

              <div
                className="mt-2 text-danger d-flex justify-content-center align-items-center cursor-pointer logout-btn"
                onClick={handleLogout}
              >
                <span className="material-symbols-outlined">logout</span>
                <span className="ms-2">Log out</span>
              </div>
            </center>
          </div>
        </div>
      </div>
      {isDotCollapseOpen && <div className="background-dim"></div>}
    </div>
  );
}

export default Profile;
