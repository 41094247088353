/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";

import About from "../../Assets/Homepage/About.png";
import Home1 from "../../Assets/Homepage/HomeFood1new.jpg";
import Home2 from "../../Assets/Homepage/HomeFood2new.jpg";
import Home3 from "../../Assets/Homepage/HomeFood3new.jpg";

import logo from "../../Assets/Header/logo.svg";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchResearcherData } from "../../Service/ApiServices";
import "../../Style/UserModel.scss";

function HomeScreen() {
  const { dataresearch } = useSelector((state) => state.researcher);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchResearcherData("userid"));
  }, [dispatch]);
  return (
    <div>
      <nav className=" fixed-nav">
        <div className="mt-3 mb-2 d-flex justify-content-between align-items-center">
          <div className="">
            <img className="w-75" src={logo} />
          </div>
          <Link to="/login" className="submit-btn  link-tag">
            <div className="button submit-btn px-3 py-2 cursor-pointer">
              Login
            </div>
          </Link>
        </div>
      </nav>
      <div className="homepage">
        <div
          id="carouselExampleDark"
          className="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={1}
              aria-label="Slide 2"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={2}
              aria-label="Slide 3"
            />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval={5000}>
              <img src={Home1} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block ">
                <h5 className="fs-1 headings">Comprehensive Food Data Input</h5>
                <p className="fs-5">
                  The food database empowers users to input comprehensive
                  details of food items, encompassing macro and micronutrient
                  content for informed dietary analysis. Additionally, it
                  integrates NOVA scores, aiding in categorizing food based on
                  processing levels, and much more.
                </p>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval={3000}>
              <img src={Home2} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5 className="fs-1">Detailed Nutritional Analysis Features</h5>
                <p className="fs-5">
                  Experience an in-depth nutritional analysis feature providing
                  comprehensive insights into the macro and micronutrient
                  composition of diverse food items.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={Home3} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5 className="fs-1">
                  Informed Dietary Choices Through Tracking Foods
                </h5>
                <p className="fs-5">
                  Empower your dietary decisions with comprehensive food
                  tracking, offering insights into Glycemic Index (GI), Glycemic
                  Load (GL), and Satiety Score(FF) for a deeper understanding
                  and healthier lifestyle choices.
                </p>
              </div>
            </div>
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div className="mt-5 cardsdata">
          <div className="w-100">
            <div className="row col-md-12 d-flex justify-content-center text-dark">
              <a className="card1 card-backrgroud7 shadow col-md-3 text-dark">
                <h3>Fullness Factor (FF)</h3>
                <p className="small ">
                  The Fullness Factor is calculated from the food's nutrient
                  content, using values from those nutrients that have been
                  shown experimentally to have the greatest impact on satiety.
                </p>
                <div className="go-corner card-backrgroud7">
                  <div className="go-arrow"></div>
                </div>
              </a>
              <a className="card1 card-backrgroud8 shadow col-md-3 text-dark">
                <h3 className="headingscard">Net carbohydrates</h3>
                <p className="small">
                  The concept of net carbs is based on the principle that not
                  all carbohydrates affect the body in the same manner.
                </p>
                <div className="go-corner card-backrgroud8">
                  <div className="go-arrow"></div>
                </div>
              </a>

              <a className="card1 card-backrgroud3 shadow col-md-3 text-dark">
                <h3 className="headingscard">NOVA score</h3>
                <p className="small">
                  The Nova classification is a framework for grouping edible
                  substances based on the extent and purpose of food processing
                  applied to them.
                </p>
                <div className="go-corner card-backrgroud3">
                  <div className="go-arrow"></div>
                </div>
              </a>
              <a className="card1 card-backrgroud4 shadow col-md-3 text-dark">
                <h3 className="headingscard">Glycemic Index (GI)</h3>
                <p className="small">
                  The glycemic index is a number from 0 to 100 assigned to a
                  food, with pure glucose arbitrarily given the value of 100,
                  which represents the relative rise in the blood glucose level
                  two hours after consuming that food.
                </p>
                <div className="go-corner card-backrgroud4">
                  <div className="go-arrow"></div>
                </div>
              </a>
            </div>
          </div>
          <div className="w-100">
            <div className="row col-md-12 d-flex justify-content-center">
              <a className="card1 card-backrgroud5 shadow col-md-3 text-dark">
                <h3 className="headingscard">Glycemic Load (GL)</h3>
                <p className="small">
                  The glycemic load (GL) of food is a number that estimates how
                  much the food will raise a person's blood glucose level after
                  it is eaten. One unit of glycemic load approximates the effect
                  of eating one gram of glucose.
                </p>
                <div className="go-corner card-backrgroud5">
                  <div className="go-arrow"></div>
                </div>
              </a>
              <a className="card1 card-backrgroud6 shadow col-md-3 text-dark">
                <h3 className="headingscard">Teaspoons of sugar</h3>
                <p className="small">
                  When you eat, think about the teaspoons of sugar it contains,
                  providing a clearer understanding of its carbohydrate content
                  and how it may affect your health.
                </p>
                <div className="go-corner card-backrgroud6">
                  <div className="go-arrow"></div>
                </div>
              </a>

              <a className="card1 card-backrgroud1 shadow col-md-3 text-dark">
                <h3 className="headingscard">Total food items in Database</h3>
                <p className="small mt-5 d-flex justify-content-center">
                  <h1>{dataresearch?.data?.total_approved || 0}</h1>
                </p>
                <div className="go-corner card-backrgroud1">
                  <div className="go-arrow"></div>
                </div>
              </a>

              <a className="card1 card-backrgroud2 shadow col-md-3 text-dark">
                <h3 className="headingscard">Foods linked to Therapeutic use</h3>
                <p className="small">
                  Explore the therapeutic power of foods, discovering how they
                  can aid in managing health conditions and promoting well-being
                  through their unique nutritional properties.
                </p>
                <div className="go-corner card-backrgroud2">
                  <div className="go-arrow"></div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="aboutus w-100 d-flex justify-content-center">
          <div className="card mt-5 aboutuscontent">
            <div className="row g-0 align-items-center">
              <div className="col-md-4">
                <img
                  src={About}
                  className="img-fluid rounded-start"
                  alt="..."
                />
              </div>
              <div className="col-md-8 about">
                <div className="card-body">
                  <h5 className="card-title fw-bold">ABOUT US</h5>
                  <p className="card-text ">
                    We're passionate about demystifying nutrition for everyone.
                    With our user-friendly interactive tools, we offer easy
                    access to detailed nutritional information, helping millions
                    make informed choices about their diet daily. Our commitment
                    extends to maintaining a world-renowned nutrition database,
                    ensuring reliable data on a vast array of foods, from
                    everyday staples to specialty ingredients..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="address mt-3 w-100">
          <footer className="footer">
            <div>
              <h2 className="text-light">ADDRESS</h2>
              <p>
                Vizzhy Inc.
                <br />
                No. 133 , Raheja Chancery, 2nd Floor, Brigade Road
                <br />
                Shanthala Nagar, Richmond Town,
                <br />
                Bengaluru-560025, Karnataka, INDIA
              </p>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
