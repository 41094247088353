import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Provider } from 'react-redux';
import store from '../src/Service/store/store'
import { AuthProvider } from './Service/Context/AuthProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
     <AuthProvider>
    <App />
    </AuthProvider>
  </Provider>
);
reportWebVitals();
