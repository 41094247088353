import React from "react";
import Button from "../../Buttons/Button";

const DeleteRecordingModel = ({closeModalViewdeleteRecording,setaudiomodelurl,setaudioname,setblobstorage}) => {
    const handledelete = ()=>{
        setblobstorage()
        setaudiomodelurl('')
        setaudioname('')
        closeModalViewdeleteRecording()
    }
    return (
        <div>
            <div className='text-center'>
                 <span className="mb-4 material-symbols-outlined mt-4 header-SubmitResearchermodel">graphic_eq</span>
                <div>Are you sure you want to Delete the recording?</div>
                <div className='submit-btn p-2 mx-4 mt-3' onClick={handledelete}><Button value="Delete"/></div>
                <div onClick={closeModalViewdeleteRecording} className='cancel-btn p-2 mx-4 mt-3 mb-4'><Button value="No"/></div>
            </div> 
        </div>
  );
};

export default DeleteRecordingModel;