import PropTypes from "prop-types";
import React, { useEffect } from "react";
import '../../Style/Model.scss'

function Model({ showModal, content, data, cancel }) {
    useEffect(() => {
        const body = document.body;
        if (showModal) {
          body.style.overflow = "hidden";
        } else {
          body.style.overflow = "auto";
        }
    
        return () => {
          body.style.overflow = "auto";
        };
      }, [showModal]);
    
      const modalStyle = {
        display: showModal ? "block" : "none",
      };
    
      const overlayStyle = {
        display: showModal ? "block" : "none",
      };
    return (
        <div className="Resuablemodal">
      <div className="overlay" style={overlayStyle}></div>
      <div
        className={`modal ${showModal ? "show" : ""}`}
        style={modalStyle}
        tabIndex="-1"
        role="dialog"
      >
        <div
          className={`modal-dialog modal-dialog-centered ${data}`}
          role="document"
        >
          <div className="modal-content ">
            {cancel}
            <div className="modal-body">{content}</div>
          </div>
        </div>
      </div>
    </div>
    )
}
Model.propTypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
  };

export default Model

