import { createSlice } from "@reduxjs/toolkit";
import StatusCode from "../../../Utils/StatusCode";
import {
  ViewfetchFoodDetails,
  countresearcherdashboard,
  fetchIngredientSuggestions,
  fetchResearcherData,
  fetchResearchertables,
  fetchUploadedIngredients,
  uploadFoodDetails,
} from "../../ApiServices";

const ResearcherSlice = createSlice({
  name: "researcher",
  initialState: {
    data: [],
    status: "",
    error: null,
    datavalue: [],
    countdata: [],
    dataresearch:[],
    ingredients:[],
    fetchResearchertablesstatus:""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(countresearcherdashboard.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(countresearcherdashboard.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.countdata = action.payload;
      })
      .addCase(countresearcherdashboard.rejected, (state) => {
        state.status = StatusCode.ERROR;
      })

      .addCase(fetchResearchertables.pending, (state) => {
        state.fetchResearchertablesstatus = StatusCode.LOADING;
      })
      .addCase(fetchResearchertables.fulfilled, (state, action) => {
        state.fetchResearchertablesstatus = StatusCode.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchResearchertables.rejected, (state) => {
        state.fetchResearchertablesstatus = StatusCode.ERROR;
      })

      // .addCase(uploadFoodDetails.pending, (state) => {
      //     state.loading = true;
      //     state.error = null;
      //   })
      //   .addCase(uploadFoodDetails.fulfilled, (state) => {
      //     state.loading = false;
      //   })
      //   .addCase(uploadFoodDetails.rejected, (state, action) => {
      //     state.loading = false;
      //     state.error = action.payload;
      //   })

      //   .addCase(fetchIngredientSuggestions.pending, (state) => {
      //     state.loading = true;
      //     state.error = null;
      //   })
      //   .addCase(fetchIngredientSuggestions.fulfilled, (state, action) => {
      //     state.loading = false;
      //     state.ingredients = action.payload;
      //   })
      //   .addCase(fetchIngredientSuggestions.rejected, (state, action) => {
      //     state.loading = false;
      //     state.error = action.payload;
      //   });

      .addCase(fetchIngredientSuggestions.pending, (state) => {
        state.status = StatusCode.LOADING;
        state.error = null;
      })
      .addCase(fetchIngredientSuggestions.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchIngredientSuggestions.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.payload;
      })

      .addCase(uploadFoodDetails.pending, (state) => {
        state.status = StatusCode.LOADING;
        state.error = null;
      })
      .addCase(uploadFoodDetails.fulfilled, (state) => {
        state.status = StatusCode.IDLE;
      })
      .addCase(uploadFoodDetails.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.payload;
      })

      .addCase(ViewfetchFoodDetails.pending, (state) => {
        state.status = StatusCode.LOADING;
        state.error = null;
      })
      .addCase(ViewfetchFoodDetails.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.datavalue = action.payload;
      })
      .addCase(ViewfetchFoodDetails.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error = action.payload;
      })
      .addCase(fetchResearcherData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchResearcherData.fulfilled, (state, action) => {
        state.loading = false;
        state.dataresearch = action.payload;
      })
      .addCase(fetchResearcherData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchUploadedIngredients.pending, (state) => {
        state.loading = StatusCode.LOADING;
        state.error = null;
      })
      .addCase(fetchUploadedIngredients.fulfilled, (state, action) => {
        state.loading = StatusCode.IDLE;
        state.ingredients = action.payload;
      })
      .addCase(fetchUploadedIngredients.rejected, (state, action) => {
        state.error =StatusCode.ERROR;
      });
  },
});

export default ResearcherSlice.reducer;
