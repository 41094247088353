import React from "react";
import logo from "../../Assets/Header/logo.svg";
import Notification from "../Notification/Notification";
import Profile from "../Profile/Profile";
import "../../Style/UserModel.scss";
import { useAuth } from "../../Service/Context/AuthProvider";

function Header() {
  const {  userData } = useAuth();
  

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-header mb-5">
      <div className="container-fluid navbar-text-alignment px-5">
        <div>
          <img src={logo} alt="" className="logo" />
        </div>
        {/* <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
          <div className="d-flex align-items-center">
            <div className="mx-2 mt-1">
              <Notification userData={userData} />
            </div>

            <div className="mx-2 mt-1">
              <Profile userData={userData} />
            </div>
          </div>
      </div>
    </nav>
  );
}

export default Header;
