import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadFoodDetails } from "../../../Service/ApiServices";
import { useAuth } from "../../../Service/Context/AuthProvider";
import Button from "../../Buttons/Button";


const DraftResearcherModel = ({
  closeModalDraft,
  formData,
  selectedImages,
  statekey,
  status,
  card, blobstorage, audioname, audiomodelurl
}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const formatDate = (statekey) => {
    const dateString = statekey?.data?.allocated_date;
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(dateString)) {
      return statekey;
    } else {
      const [day, month, year] = dateString.split('-');
      statekey.data.allocated_date = `${year}-${month}-${day}`;

      return statekey;
    }
  };

  const uploadData = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      
  
      await dispatch(
        uploadFoodDetails({
          formData,
          selectedImages,
          userData,
          statekey: formatDate(statekey),
          status,
          token: userData.token,
          blobstorage,
          audioname,
          audiomodelurl,
          navigate,
        })
      ).unwrap();
  
      toast.success("Data registered successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { marginTop: "60px" },
      });
  
      closeModalDraft();
      navigate("/vfs/researcher-dashboard", { state: { card } });
    } catch (error) {
      toast.error("Error submitting data: " + error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { marginTop: "60px" },
      });
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div>
      <div className="text-center">
        <span className="mb-4 material-symbols-outlined mt-4 header-SubmitResearchermodel">
          bookmark_added
        </span>
        <div>Do you want to save it as</div>
        <div>draft?</div>
        <div onClick={uploadData} className="submit-btn p-2 mx-4 mt-3">
        <Button value={isLoading ? <span className="loader"></span> : "Yes"} disabled={isLoading} />
        </div>
        <div
          onClick={closeModalDraft}
          className="cancel-btn p-2 mx-4 mt-3 mb-4"
        >
          <Button value="No" />
        </div>
      </div>
    </div>
  );
};

export default DraftResearcherModel;