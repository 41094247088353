import React, { Suspense, lazy, useEffect, useState } from 'react';
import Card from '../../Utils/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../Service/Context/AuthProvider';
import { getQaNumber } from '../../Service/ApiServices';
import { useLocation, useNavigate } from "react-router-dom";
const QaRecApproved = lazy(() => import('../../Page/Qa/QaRecApproved/QaRecApproved'));
const QaRecReview = lazy(() => import('../../Page/Qa/QaRecReview/QaRecReview'));
const QaSentRework = lazy(() => import('../../Page/Qa/QaSentRework/QaSentRework'));
const QaReworkReview = lazy(() => import('../../Page/Qa/QaReworkReview/QaReworkReview'));
function QaDashboard() {
    const location = useLocation();
    const navigate = useNavigate();
    const { userData } = useAuth();

    const statekey = location?.state;
    const [activeIndex, setActiveIndex] = useState(statekey ? statekey?.card : 1);
    const handleClick = (index) => {
        setActiveIndex(index);
    };
    useEffect(() => {
        if (statekey) {
            navigate(location.pathname, { replace: true });
        }
    }, [statekey]);
    const checkActive = (index, className) =>
        activeIndex === index ? className : "";

    const dispatch = useDispatch();
    const { datacount } = useSelector(state => state.qaSlice);
    useEffect(() => {
        if (userData) {
            dispatch(getQaNumber({ token: userData.token,navigate }))
        }

    }, [dispatch, userData]);
    return (
        <div>

            <>
                <div className='row main-cards' style={{ gap: "2.5%" }}>
                    <div
                        className={`col-md ${checkActive(1, "dashboard-active")}`}
                        onClick={() => handleClick(1)}
                    >
                        <Card text="Recipes to Review" img="pending" value={datacount?.ready_to_review || 0} />
                    </div>
                    <div
                        className={`col-md ${checkActive(2, "dashboard-active")}`}
                        onClick={() => handleClick(2)}
                    >
                        <Card text="Recipes sent to Rework" img="source_notes" value={datacount?.rework || 0} />
                    </div>
                    <div
                        className={`col-md ${checkActive(3, "dashboard-active")}`}
                        onClick={() => handleClick(3)}
                    >
                        <Card text="Reworked Recipes to Review" img="source_notes" value={datacount?.rework_ready_to_review || 0} />
                    </div>
                    <div
                        className={`col-md ${checkActive(4, "dashboard-active")}`}
                        onClick={() => handleClick(4)}
                    >
                        <Card text="Recipes Approved" img="preliminary" value={datacount?.approved || 0} />
                    </div>
                </div>
            </>

            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    {activeIndex === 1 && <QaRecReview />}
                    {activeIndex === 2 && <QaSentRework />}
                    {activeIndex === 3 && <QaReworkReview />}
                    {activeIndex === 4 && <QaRecApproved />}
                </Suspense>
            </div>
        </div>
    );
}

export default QaDashboard;
