import React from "react";
import { useNavigate } from "react-router-dom";
import vizzhylogo from "../../../Assets/Header/vizzhy_logo.svg";
import Loginfood from "../../../Assets/Homepage/Loginfood.svg";
import "../../../Style/UserModel.scss";
import Button from "../../../Utils/Buttons/Button";
function ResetSuccess() {
  const navigate = useNavigate();

  const handellogin = () => {
    navigate("/login");
  };

  return (
    <div className="login-page">
      <div className="login-main row w-100">
        <div className="col-sm-7">
          <img src={vizzhylogo} alt="vizzhyLogo" className="vizzhy-logo" />
          <img className="login-img" src={Loginfood} alt="img" />
        </div>

        <div className="col-sm-5 d-flex justify-content-center align-items-center">
          <div>
            <form className="login-from">
              <p className="fw-bold fs-3">Password Reset Successfully</p>

              <div
                className="button submit-btn px-1 py-2 cursor-pointer"
                onClick={handellogin}
              >
                <Button value="Login" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetSuccess;
