import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import CryptoJS from 'crypto-js';
const AuthContext = createContext();


export const useAuth = () => useContext(AuthContext);


export const AuthProvider = ({ children }) => {
  const [userupdatereducer, userupdateforceUpdate] = useReducer(x => x + 1, 0)
  const [userData, setuserData] = useState()
  const [data, setData] = useState()
  const [remembereme,setremembereme]=useState()
  const storedUserData = localStorage.getItem('userData');
  const storeddata = localStorage.getItem('Data');
  const updateUserData = (newUserData) => {
    localStorage.setItem('userData', encryptData(newUserData));
    setuserData(newUserData)

  };
  const updateUseractive = (newUserData) => {
    localStorage.setItem('Data', encryptData(newUserData));
    setData(newUserData)
  };

  const logoutremove = ()=>{
    localStorage.removeItem("Data");
    localStorage.removeItem("userData");
    
  }
  const obfuscateData = (data) => {
    return btoa(JSON.stringify(data));
  };

  const getrememberme = ()=>{
    return localStorage.getItem("adminRememberedData");
  }
  const removerememberme = ()=>{
     localStorage.removeItem("adminRememberedData");
  }
  const storeEncryptedData = (data) =>{
    const encryptedData = obfuscateData(data);
    localStorage.setItem("adminRememberedData", encryptedData);
  }

  useEffect(() => {
    if (storedUserData) {
      setuserData(decryptData(storedUserData))
    }
  }, []);

  useEffect(() => {
    if (storeddata) {
      setData(decryptData(storeddata));
    }
  }, [])
  

  return (
    <AuthContext.Provider value={{ userData, data, updateUserData, updateUseractive ,logoutremove,getrememberme,storeEncryptedData,removerememberme ,userupdateforceUpdate,userupdatereducer}}>
      {children}
    </AuthContext.Provider>
  );
};


const secretKey = 'VizzhyFood';


const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};


const decryptData = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};